.socure-c-content-outer-container {
  box-sizing: border-box;
  overflow: auto;
  border: 1px solid var(--socure-global--color-gray-80);
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.socure-c-content-outer-container::-webkit-scrollbar {
  width: 0;
}

.socure-c-content-inner-container {
  font-size: var(--socure-global--FontSize--3xs);
  line-height: 16px;
  color: var(--socure-global--color-gray-20);
  letter-spacing: .02em;
}

.socure-c-tabs-outer-container {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.socure-c-tabs-outer-container>.socure-c-tab-outer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 32px;
}

.socure-c-tabs-outer-container>.socure-c-tab-outer-container:first-child {
  margin-left: 4px;
}

.socure-c-tabs-outer-container::-webkit-scrollbar {
  width: 0;
}

.socure-c-tab-outer-container-normal {
  cursor: pointer;
}

.socure-c-tab-outer-container-disabled {
  cursor: not-allowed;
}

.socure-c-tab-inner-container-text {
  font-size: var(--socure-global--FontSize--xs);
  line-height: var(--socure-global--LineHeight-24);
  color: var(--socure-global--color-black);
}

.socure-c-tab-inner-container-text-inactive {
  font-weight: var(--socure-global--FontWeight--normal);
  color: var(--socure-global--color-gray-40);
}

.socure-c-tab-inner-container-text-active {
  font-weight: var(--socure-global--FontWeight--bold);
}

.socure-c-tab-outer-container-line {
  margin: 0 4px;
}

.socure-c-tab-outer-container-line-inactive {
  border: 2px solid var(--socure-global--color-gray-95);
}

.socure-c-tab-outer-container-line-active {
  width: 32px;
  border: 1px solid var(--socure-global--color-orange-60);
}

