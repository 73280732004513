.socure-c-textArea-OutterContainer {
  --socure-c-textArea--LineHeight: var(--socure-global--LineHeight-24);
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.socure-c-textArea-OutterContainer.socure-c-textArea-expanded {
  display: flex;
  flex: 1;
}

.socure-c-textArea-OutterContainer.socure-c-textArea-disabled {
  opacity: .4;
}

.socure-c-textArea-InnerContainer {
  position: relative;
  align-items: flex-end;
  width: 100%;
}

.socure-c-textArea-InnerContainer>textarea {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 12px;
  margin-top: 12px;
  font-size: var(--socure-global--FontSize--xs);
  line-height: var(--socure-global--LineHeight-24);
  border: 1px solid var(--socure-global--color-text-input-border-2);
  color: var(--socure-global--color-text-input-border-2);
  border-radius: 4px;
}

.socure-c-textArea-InnerContainer>textarea:not(.socure-c-textArea-input-validation-error):focus {
  color: var(--socure-global--colo\r-black);
  border: 1px solid var(--socure-global--color-teal-border);
  outline: 2px solid var(--socure-global--color-teal-outline-input) !important;
}

.socure-c-textArea-InnerContainer>textarea.socure-c-textArea-input-validation-error:focus {
  outline: 2px solid var(--socure-global--color-text-input-outline-error) !important;
}

.socure-c-textArea-InnerContainer>textarea ~ .socure-c-textArea-placeholder-label {
  font-size: var(--socure-global--FontSize--2xs);
  font-weight: var(--socure-global--FontWeight--bold);
  line-height: var(--socure-global--LineHeight-24);
  color: var(--socure-global--color-switch-label-disabled);
  background-color: var(--socure-global--color-white);
}

.socure-c-textArea-InnerContainer>textarea:focus ~ .socure-c-textArea-placeholder-label {
  top: 0;
  left: 8px;
  padding: 2px 4px;
  color: var(--socure-global--color-switch-label-disabled) !important;
}

.socure-c-textArea-InnerContainer>textarea[type="number"] {
  appearance: textfield;
}

.socure-c-textArea-InnerContainer>textarea::-webkit-outer-spin-button,
.socure-c-textArea-InnerContainer>textarea::-webkit-inner-spin-button {
  appearance: none;
}

.socure-c-textArea-placeholder-label {
  position: absolute;
  top: 0;
  left: 8px;
  padding: 2px 4px;
  font-size: var(--socure-global--FontSize--xs);
  line-height: var(--socure-global--LineHeight-24);
  color: var(--socure-global--color-black);
  transition: 300ms;
  transition-property: all;
}

.socure-c-textArea-placeholder-label-validation-success {
  color: var(--socure-global--color-gray-40) !important;
}

.socure-c-textArea-input-hasText {
  top: 0;
  left: 8px;
  padding: 2px 4px;
  background-color: var(--socure-global--color-white);
}

.socure-c-textArea-disabled-input {
  pointer-events: none;
  cursor: not-allowed;
  background-color: var(--socure-secondary-button-hover);
  border: 1px solid var(--socure-secondary-button-hover) !important;
}

.socure-c-textArea-validation-error-label {
  position: relative;
  margin-top: 4px;
  font-size: var(--socure-global--FontSize--2xs);
  line-height: var(--socure-global--LineHeight-16);
  color: var(--socure-global--color-text-input-error);
}

.socure-c-textArea-input-validation-error {
  border: 1px solid var(--socure-global--color-text-input-error) !important;
  outline: none !important;
}

.socure-c-textArea-input-validation-success {
  border: 1px solid var(--socure-global--color-gray-80);
  outline: none !important;
}

.socure-c-textArea-validation-error-label-visible {
  display: inline-block;
}

.socure-c-textArea-validation-error-label-hidden {
  display: none;
}

.socure-c-textArea-input-disabled-resize {
  resize: none;
}

