.socure-c-otpinput-container {
  display: flex;
  flex-direction: row;
  margin: 20px;
}

.socure-c-otpinput {
  box-sizing: border-box;
  width: 40px;
  height: 56px;
  margin-right: 20px;
  text-align: center;
  background: var(--socure-global--color-white);
  border: 1px solid var(--socure-global--color-gray-80);
  border-radius: 4px;
}

.socure-c-otpinput:focus {
  border: 1px solid var(--socure-global--color-teal-border);
  outline: 2px solid var(--socure-global--color-teal-outline-input);
}

