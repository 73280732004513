.socure-c-menuItem__disabled {
  color: var(--socure-global--color-gray-60);
  pointer-events: none;
  background-color: var(--socure-global--color-gray-90);
  border: 1px solid var(--socure-global--color-gray-90);
}

.socure-c-menuItem__border {
  border-top: 1px solid var(--socure-global--color-menu-border);
}

.socure-c-menuItem {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 48px;
  padding: 16px 48px 16px 16px;
  padding: 16px;
  font-size: var(--socure-global--FontSize--xs);
  font-weight: var(--socure-global--FontWeight--normal);
  line-height: 18px;
  text-align: left;
  cursor: pointer;
}

.socure-c-menuItem label {
  cursor: pointer;
}

.socure-c-menuItem:focus,
.socure-c-menuItem:hover {
  font-weight: var(--socure-global--FontWeight--bold);
  background-color: var(--socure-global--color-gray-90);
}

.socure-c-menu__container {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.socure-c-menu__container .socure-c-menu-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.socure-c-menu__container .socure-c-menu-trigger.socure-c-menu-regular {
  width: 32px;
  height: 32px;
}

.socure-c-menu__container .socure-c-menu-trigger.socure-c-menu-regular:hover {
  background: var(--socure-global--color-menu-hover-color);
  opacity: .9;
}

.socure-c-menu__container .socure-c-menu-trigger.socure-c-menu-regular:active {
  background: var(--socure-global--color-menu-hover-color);
}

.socure-c-menu__container .socure-c-menu-trigger.ssocure-c-menu-custom-menu {
  transition: all 300ms linear;
}

.socure-c-menuItem__List {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 190px;
  border: 1px solid var(--socure-global--color-gray-80);
  box-shadow: 0 32px 64px rgba(44,9,12,0.1);
}

.socure-c-menuItem__List.socure-c-nestedMenuItem__List {
  position: absolute;
  top: 0;
  left: -102%;
  background: var(--socure-global--color-white);
}

.socure-c-menuItem__List.socure-c-nestedMenuItem__List .socure-c-menuItem {
  text-transform: capitalize;
}

.socure-c-menuItem__Icon {
  position: absolute;
  display: inline-block;
}

.socure-c-menu-trigger-icon-container {
  display: flex;
  flex-direction: row;
  transition: transform 300ms linear;
}

.socure-c-menu-trigger-icon-container.socure-c-menu-tigger-on-select {
  transform: rotate(45deg);
}

.socure-c-lb-each-row.socure-c-lb-condition-row .socure-c-menuItem__List #addSubGroup,
.socure-c-lb-each-row.socure-c-lb-condition-row .socure-c-menuItem__List #addSubCondition {
  display: none;
}

.socure-c-lb-each-row.socure-c-lb-group-row .socure-c-menuItem__List #addSubGroup {
  display: block;
}

.socure-c-lb-each-row.socure-c-lb-group-row .socure-c-menuItem__List #addGroup {
  display: none;
}

.socure-c-lb-each-row.socure-c-lb-group-row .socure-c-lb-condition-row .socure-c-menuItem__List #addSubGroup {
  display: none;
}

.socure-c-lb-each-row.socure-c-lb-group-row .socure-c-lb-condition-row .socure-c-menuItem__List #addGroup {
  display: block;
}

