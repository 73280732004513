:root {
  --socure-global--color-menu-text: #fff;
  --socure-global--color-menu-icon: #fff;
  --socure-global--color-menu-indicator: #ff6900;
  --socure-global--color-menu-active-icon: #ff6900;
  --socure-global--color-menu-icon-dull: #5b5f66;
  --socure-global--color-menu-item-hover: #262c33;
  --socure-global--color-text-box-border: #3c4454;
  --socure-global--color-text-box-placeholder: #a4a5a6;
  --socure-global--color-checkbox-placeholder: #a4a5a6;
  --socure-global--color-text-input-icon: #33363b;
  --socure-global--color-filter-button: #1d2126;
  --socure-global--color-filter-button-text: #fff;
  --socure-global--color-data-table-header: #1e2833;
  --socure-global--color-data-table-header-border: #c3c4c5;
  --socure-global--color-data-table-header-text: #c3c4c5;
  --socure-global--color-data-table-body: #151515;
  --socure-global--color-data-table-body-border: #1e2833;
  --socure-global--color-data-table-pagination: #1e2833;
  --socure-global--color-data-table-progress-text: #1e2833;
  --socure-global--color-content-background: #fff;
  --socure-global--color-content-footer-border: #c3c4c5;
  --socure-global--color-secondary-button-active: #e3e4e6;
  --socure-global--color-group-list-border: #454d5f;
  --socure-global--color-group-list-icon: #6c7689;
  --socure-global--color-checkbox-border: #3c4454;
  --socure-global--color-radio-border: #3c4454;
  --socure-global--color-radio-background: #40464d;
  --socure-global--color-search-dark-background: #40464d;
  --socure-global--color-account-env-popper: #2b3139;
  --socure-global--color-transaction-color: #247bfc;
  --socure-global--color-text-input-border: #454d5f;
  --socure-global--color-text-input-border-2: #222;
  --socure-global--color-text-input-label: #6c7689;
  --socure-global--color-text-input-error: #e51a1a;
  --socure-global--color-text-input-outline-error: #F6C6C6;
  --socure-global--color-text-input-eye-normal: #c3c4c5;
  --socure-global--color-text-input-eye-normal-2: #555;
  --socure-global--color-separator: #4A4E53;
  --socure-global--color-text-input-eye-hover: #6c7689;
  --socure-global--color-text-input-eye-mouse-down: #454d5f;
  --socure-global--color-dropdown-border: #454d5f;
  --socure-global--color-dropdown-label: #6c7689;
  --socure-global--color-modal-border: #e3e4e6;
  --socure-global--color-tipoff-border: #6c7689;
  --socure-global--color-logic-builder-delete: #c3c4c5;
  --socure-global--color-logic-builder-border: #c3c4c5;
  --socure-global--color-logic-builder-group-border: #6c7689;
  --socure-global--color-decision-builder-background: #0e1115;
  --socure-global--color-each-decision-background: #2b3139;
  --socure-global--color-each-decision-drag-background: #40464d;
  --socure-global--color-ghost-button-color: #e3e4e6;
  --socure-global--color-dark-button-color: #1d2126;
  --socure-global--color-dark-button-active-color: #0e1115;
  --socure-global--color-dark-button-hover-color: #666;
  --socure-global--cb-disabled-border: #ccc;
  --socure-global--cb-gray-form-label: #999;
  --socure-global--color-dark-dropdown-background: #1d2126;
  --socure-global--color-dark-popper-background: #1d2126;
  --socure-global--color-dark-radio-button-color: #26272c;
  --socure-global--color-popver-heading: #1e2833;
  --socure-global--color-popver-footer: #e3e4e6;
  --socure-global--color-option-select-icon: #454d5f;
  --socure-global--color-option-select-background: #e3e4e6;
  --socure-global--color-option-select-controls-border: #e3e4e6;
  --socure-global--color-info-pill-outline-border: #e3e4e6;
  --socure-global--color-menu-border: #e3e4e6;
  --socure-global--color-menu-hover-color: #e3e4e6;
  --socure-global--color-tab-container-border: #c3c4c5;
  --socure-global--color-file-upload-background: #f2f2f2;
  --socure-global--color-file-upload-border: #454d5f;
  --socure-global--color-file-upload-icon: #22272f;
  --socure-global--color-file-upload-text-default: #6c7689;
  --socure-global--color-orange-20: #803400;
  --socure-global--color-orange-40: #b2520e;
  --socure-global--color-orange-60: #ff6900;
  --socure-global--color-orange-80: #ff8200;
  --socure-global--color-orange-81: #de6612;
  --socure-global--color-orange-100: #ffb480;
  --socure-global--color-blue-20: #141b4d;
  --socure-global--color-blue-40: #24318b;
  --socure-global--color-blue-60: #3447ca;
  --socure-global--color-blue-80: #a1a9e7;
  --socure-global--color-blue-100: #dfe2f7;
  --socure-global--color-gray-20: #454d5f;
  --socure-global--color-gray-30: #656565;
  --socure-global--color-gray-40: #6c7689;
  --socure-global--color-gray-60: #a6aebf;
  --socure-global--color-gray-80: #dde3ee;
  --socure-global--color-gray-90: #f5f7fc;
  --socure-global--color-gray-95: #f7f8fb;
  --socure-global--color-gray-96: #e9e9eb;
  --socure-secondary-button-hover: #f0f0f0;
  --socure-global--color-white: #fff;
  --socure-global--color-black: #22272f;
  --socure-global--color-black-01: #151515;
  --socure-global--color-black-02: #070000;
  --socure-global--color-green-20: #0a5c30;
  --socure-global--color-green-40: #10934d;
  --socure-global--color-green-60: #16c568;
  --socure-global--color-green-80: #93ebbc;
  --socure-global--color-green-100: #d2f9e4;
  --socure-global--color-yellow-20: #7a4800;
  --socure-global--color-yellow-40: #b26800;
  --socure-global--color-yellow-60: #ffbf00;
  --socure-global--color-yellow-80: #ffdf80;
  --socure-global--color-yellow-100: #fff2cc;
  --socure-global--color-red-20: #6e0c0c;
  --socure-global--color-red-40: #a51212;
  --socure-global--color-red-60: #e61a1a;
  --socure-global--color-red-80: #f28c8c;
  --socure-global--color-red-100: #fad1d1;
  --socure-global--color-red-120: #fac7c7;
  --socure-global--color-cyan-20: #0f5a6b;
  --socure-global--color-cyan-40: #1787a1;
  --socure-global--color-cyan-60: #18b8dc;
  --socure-global--color-cyan-80: #8fdeef;
  --socure-global--color-cyan-100: #d2f2f9;
  --socure-global--color-teal-button: #237791;
  --socure-global--color-teal-border: #2b96b7;
  --socure-global--color-teal-outline-input: #aee6f3;
  --socure-global--color-teal-button-hover: #125064;
  --socure-global--chips-background: #e4e4e4;
  --socure-global--chips-selected-background: #237791;
  --socure-global--chips-text-color: #161616;
  --socure-global--chips-icon-color: #0a0b11;
  --socure-global--datagrid-hover-color: #e4efff;
  --socure-global--color-switch-background: #54565a;
  --socure-global--color-switch-label-disabled: #858585;
  --socure-global--color-switch-background-disabled: #e1e0df;
  --socure-global--alert-text-color: #0e1115;
  --socure-global--alert-inline-information-color: #e1e0df;
  --socure-global--alert-inline-success-color: rgba(22,197,104,0.101961);
  --socure-global--alert-inline-error-color: #F6C6C6;
  --socure-global--alert-inline-timeout-color: rgba(255,216,0,0.2);
  --socure-global--alert-information-color: #26272c;
  --socure-global--alert-error-color: #e51a1a;
  --socure-global--alert-success-color: #17ad5d;
  --socure-global--alert-timeout-color: #ffd800;
  --socure-global--color-decision-outcome-background: #26272c;
  --socure-global--color-decision-condition-ellipse: #4f5359;
  --socure-global--color-decision-default-outcome-border: #17cc29;
  --socure-global--color-decision-section-background: #2c3138;
  --socure-global--color-decision-icon-color: #e8e8e8;
  --socure-global--color-decision-list-color: #e1e0df;
  --socure-global--color-decision-border-color: #858585;
  --socure-global-color-original-black: #000;
  --socure-global--color-decision-collapsible-background: #f0efef;
  --socure-global--color-decision-reasoncode-pill: #ffe8e8;
  --socure-global--color-decision-condition-button: #18a0fb;
  --socure-global--color-decision-group-expand-icon: #475467;
  --socure-global--color-decision-group-container-border: #dedede;
  --socure-global--color-decision-dashed-line-color: #dadce0;
  --socure-global--color-decision-module-list-button-color: #b2b2b2;
  --socure-global--color-decision-highlight-condition: #edfff5;
  --socure-global--button-focus-border-color: #7EA8B5;
  --socure-global--color-watchlist-settings-text: #767676;
}

.socure-color--orange-20 {
  color: var(--socure-global--color-orange-20) !important;
}

.socure-color--orange-40 {
  color: var(--socure-global--color-orange-40) !important;
}

.socure-color--orange-60 {
  color: var(--socure-global--color-orange-60) !important;
}

.socure-color--orange-80 {
  color: var(--socure-global--color-orange-80) !important;
}

.socure-color--orange-100 {
  color: var(--socure-global--color-orange-100) !important;
}

.socure-color--blue-20 {
  color: var(--socure-global--color-blue-20) !important;
}

.socure-color--blue-40 {
  color: var(--socure-global--color-blue-40) !important;
}

.socure-color--blue-60 {
  color: var(--socure-global--color-blue-60) !important;
}

.socure-color--blue-80 {
  color: var(--socure-global--color-blue-80) !important;
}

.socure-color--blue-100 {
  color: var(--socure-global--color-blue-100) !important;
}

.socure-color--gray-20 {
  color: var(--socure-global--color-gray-20) !important;
}

.socure-color--gray-40 {
  color: var(--socure-global--color-gray-40) !important;
}

.socure-color--gray-60 {
  color: var(--socure-global--color-gray-60) !important;
}

.socure-color--gray-80 {
  color: var(--socure-global--color-gray-80) !important;
}

.socure-color--gray-90 {
  color: var(--socure-global--color-gray-90) !important;
}

.socure-color--black {
  color: var(--socure-global--color-black) !important;
}

.socure-color--white {
  color: var(--socure-global--color-white) !important;
}

.socure-color--green-20 {
  color: var(--socure-global--color-success-20) !important;
}

.socure-color--green-40 {
  color: var(--socure-global--color-success-40) !important;
}

.socure-color--green-60 {
  color: var(--socure-global--color-success-60) !important;
}

.socure-color--green-80 {
  color: var(--socure-global--color-success-80) !important;
}

.socure-color--green-100 {
  color: var(--socure-global--color-success-100) !important;
}

.socure-color--yellow-20 {
  color: var(--socure-global--color-yellow-20) !important;
}

.socure-color--yellow-40 {
  color: var(--socure-global--color-yellow-40) !important;
}

.socure-color--yellow-60 {
  color: var(--socure-global--color-yellow-60) !important;
}

.socure-color--yellow-80 {
  color: var(--socure-global--color-yellow-80) !important;
}

.socure-color--yellow-100 {
  color: var(--socure-global--color-yellow-100) !important;
}

.socure-color--red-20 {
  color: var(--socure-global--color-red-20) !important;
}

.socure-color--red-40 {
  color: var(--socure-global--color-red-40) !important;
}

.socure-color--red-60 {
  color: var(--socure-global--color-red-60) !important;
}

.socure-color--red-80 {
  color: var(--socure-global--color-red-80) !important;
}

.socure-color--red-100 {
  color: var(--socure-global--color-red-100) !important;
}

.socure-color--cyan-20 {
  color: var(--socure-global--color-cyan-20) !important;
}

.socure-color--cyan-40 {
  color: var(--socure-global--color-cyan-40) !important;
}

.socure-color--cyan-60 {
  color: var(--socure-global--color-cyan-60) !important;
}

.socure-color--cyan-80 {
  color: var(--socure-global--color-cyan-80) !important;
}

.socure-color--cyan-100 {
  color: var(--socure-global--color-cyan-100) !important;
}

.socure-background-color--orange-20 {
  background-color: var(--socure-global--color-orange-20) !important;
}

.socure-background-color--orange-40 {
  background-color: var(--socure-global--color-orange-40) !important;
}

.socure-background-color--orange-60 {
  background-color: var(--socure-global--color-orange-60) !important;
}

.socure-background-color--orange-80 {
  background-color: var(--socure-global--color-orange-80) !important;
}

.socure-background-color--orange-100 {
  background-color: var(--socure-global--color-orange-100) !important;
}

.socure-background-color--blue-20 {
  background-color: var(--socure-global--color-blue-20) !important;
}

.socure-background-color--blue-40 {
  background-color: var(--socure-global--color-blue-40) !important;
}

.socure-background-color--blue-60 {
  background-color: var(--socure-global--color-blue-60) !important;
}

.socure-background-color--blue-80 {
  background-color: var(--socure-global--color-blue-80) !important;
}

.socure-background-color--blue-100 {
  background-color: var(--socure-global--color-blue-100) !important;
}

.socure-background-color--gray-20 {
  background-color: var(--socure-global--color-gray-20) !important;
}

.socure-background-color--gray-40 {
  background-color: var(--socure-global--color-gray-40) !important;
}

.socure-background-color--gray-60 {
  background-color: var(--socure-global--color-gray-60) !important;
}

.socure-background-color--gray-80 {
  background-color: var(--socure-global--color-gray-80) !important;
}

.socure-background-color--gray-90 {
  background-color: var(--socure-global--color-gray-90) !important;
}

.socure-background-color--black {
  background-color: var(--socure-global--color-black) !important;
}

.socure-background-color--white {
  background-color: var(--socure-global--color-white) !important;
}

.socure-background-color--green-20 {
  background-color: var(--socure-global--color-success-20) !important;
}

.socure-background-color--green-40 {
  background-color: var(--socure-global--color-success-40) !important;
}

.socure-background-color--green-60 {
  background-color: var(--socure-global--color-success-60) !important;
}

.socure-background-color--green-80 {
  background-color: var(--socure-global--color-success-80) !important;
}

.socure-background-color--green-100 {
  background-color: var(--socure-global--color-success-100) !important;
}

.socure-background-color--yellow-20 {
  background-color: var(--socure-global--color-yellow-20) !important;
}

.socure-background-color--yellow-40 {
  background-color: var(--socure-global--color-yellow-40) !important;
}

.socure-background-color--yellow-60 {
  background-color: var(--socure-global--color-yellow-60) !important;
}

.socure-background-color--yellow-80 {
  background-color: var(--socure-global--color-yellow-80) !important;
}

.socure-background-color--yellow-100 {
  background-color: var(--socure-global--color-yellow-100) !important;
}

.socure-background-color--red-20 {
  background-color: var(--socure-global--color-red-20) !important;
}

.socure-background-color--red-40 {
  background-color: var(--socure-global--color-red-40) !important;
}

.socure-background-color--red-60 {
  background-color: var(--socure-global--color-red-60) !important;
}

.socure-background-color--red-80 {
  background-color: var(--socure-global--color-red-80) !important;
}

.socure-background-color--red-100 {
  background-color: var(--socure-global--color-red-100) !important;
}

.socure-background-color--cyan-20 {
  background-color: var(--socure-global--color-cyan-20) !important;
}

.socure-background-color--cyan-40 {
  background-color: var(--socure-global--color-cyan-40) !important;
}

.socure-background-color--cyan-60 {
  background-color: var(--socure-global--color-cyan-60) !important;
}

.socure-background-color--cyan-80 {
  background-color: var(--socure-global--color-cyan-80) !important;
}

.socure-background-color--cyan-100 {
  background-color: var(--socure-global--color-cyan-100) !important;
}

:root {
  --socure-global-font-family: Open Sans;
  --socure-global--font-size-root: 16;
  --socure-global--space-size-root: 16;
  --socure-global--BackgroundColor--white: #fff;
  --socure-global--BackgroundColor--gray: #f5f7fc;
  --socure-global--BackgroundColor--black: #22272f;
  --socure-global--TextColor--black: #22272f;
  --socure-global--TextColor--white: #fff;
  --socure-global--FontSize--2xl: 40px;
  --socure-global--FontSize--xl: 32px;
  --socure-global--FontSize--l: 24px;
  --socure-global--FontSize--m: 20px;
  --socure-global--FontSize--s: 16px;
  --socure-global--FontSize--xs: 14px;
  --socure-global--FontSize--2xs: 12px;
  --socure-global--FontSize--3xs: 10px;
  --socure-global--FontSize--13: 13px;
  --socure-global--FontSize--18: 18px;
  --socure-global--FontWeight--light: 400;
  --socure-global--FontWeight--normal: 400;
  --socure-global--FontWeight--bold: 700;
  --socure-global--FontWeight--semi-bold: 600;
  --socure-global--LineHeight-12: 12px;
  --socure-global--LineHeight-14: 14px;
  --socure-global--LineHeight-16: 16px;
  --socure-global--LineHeight-18: 18px;
  --socure-global--LineHeight-19: 19px;
  --socure-global--LineHeight-20: 20px;
  --socure-global--LineHeight-22: 22px;
  --socure-global--LineHeight-24: 24px;
  --socure-global--LineHeight-25: 25px;
  --socure-global--LineHeight-28: 28px;
  --socure-global--LineHeight-32: 32px;
  --socure-global--LineHeight-40: 40px;
  --socure-global--LineHeight-48: 48px;
  --socure-global--LineHeight-2xl: 40px;
  --socure-global--LineHeight-xl: 32px;
  --socure-global--LineHeight-l: 24px;
  --socure-global--LineHeight-m: 20px;
  --socure-global--LineHeight-s: 16px;
  --socure-global--LineHeight-xs: 14px;
  --socure-global--LineHeight-2xs: 12px;
  --socure-global--LineHeight-3xs: 10px;
  --socure-global--LineHeight-sm: 1.3;
  --socure-global--LineHeight-md: 1.5;
  --socure-global--link--Color: #a6aebf;
  --socure-global--link--Color--hover: #a6aebf;
  --socure-global--link--Color--light: #a6aebf;
  --socure-global--link--Color--light--hover: #a6aebf;
  --socure-global--link--Color--dark: #a6aebf;
  --socure-global--link--Color--dark--hover: #a6aebf;
  --socure-global--link--TextDecoration: none;
  --socure-global--link--TextDecoration--hover: underline;
  --socure-global--BorderWidth--s: 1px;
  --socure-global--BorderWidth--m: 2px;
  --socure-global--BorderWidth--l: 3px;
  --socure-global--BorderWidth--xl: 4px;
  --socure-global--BorderRadius--s: 3px;
  --socure-global--BorderRadius--m: 4px;
  --socure-global--BorderRadius--l: 30em;
  --socure-global--spacer--xs: 4px;
  --socure-global--spacer--s: 8px;
  --socure-global--spacer--m: 16px;
  --socure-global--spacer--l: 24px;
  --socure-global--spacer--xl: 32px;
  --socure-global--spacer--2xl: 48px;
  --socure-global--spacer--3xl: 64px;
  --socure-global--spacer--4xl: 80px;
  --socure-global--BoxShadow--s: 0 1px 2px 0 rgba(34,39,47,0.12),0 0 2px 0 rgba(34,39,47,0.06);
  --socure-global--BoxShadow--m: 0 4px 8px 0 rgba(34,39,47,0.12),0 0 4px 0 rgba(34,39,47,0.06);
  --socure-global--BoxShadow--l: 0 8px 16px 0 rgba(34,39,47,0.16),0 0 6px 0 rgba(34,39,47,0.08);
  --socure-global--BoxShadow--xl: 0 16px 32px 0 rgba(34,39,47,0.16),0 0 8px 0 rgba(34,39,47,0.1);
  --socure-global--BoxShadow--inset: inset 0 0 10 0 rgba(34,39,47,0.25);
}

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: var(--socure-global--FontWeight--normal);
}

ul {
  list-style: none;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: var(--socure-global--LineHeight-24);
  color: var(--socure-global--Color--black);
}

img,
embed,
iframe,
object,
audio,
video {
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

td,
th {
  padding: 0;
  text-align: left;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
}

html {
  font-family: var(--socure-global-font-family);
  line-height: 1.15;
}

body {
  font-family: var(--socure-global-font-family);
  font-size: var(--socure-global--FontSize--s);
  font-weight: var(--socure-global--FontWeight--normal);
  line-height: var(--socure-global--LineHeight-24);
  text-align: left;
  background-color: var(--socure-global--BackgroundColor--white);
}

a {
  font-weight: var(--socure-global--FontWeight--normal);
  color: var(--socure-global--link--Color);
  text-decoration: var(--socure-global--link--TextDecoration);
}

a:hover {
  color: var(--socure-global--link--Color--hover);
  text-decoration: var(--socure-global--link--TextDecoration--hover);
}

button,
a {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.socure-align--text-center {
  text-align: center;
}

.socure-align--text-right {
  text-align: right;
}

.socure-display--none {
  display: none;
}

.socure-display--flex {
  display: flex !important;
}

.socure-display--flex0 {
  flex: 0 0 auto !important;
}

.socure-display--flex1 {
  flex: 1 1 auto !important;
}

.socure-align--justify-left {
  justify-content: flex-end;
}

.socure-align--justify-center {
  justify-content: center;
}

.socure-align--justify-right {
  justify-content: flex-end;
}

.socure-align--left {
  align-items: flex-end;
}

.socure-align--center {
  align-items: center;
}

.socure-align--right {
  align-items: flex-end;
}

.socure-display--flex-row {
  flex-direction: row;
}

.socure-display--flex-col {
  flex-direction: column;
}

.socure-display--flex-wrap {
  flex-wrap: wrap;
}

.socure-visibility--hidden {
  visibility: hidden;
}

.socure-display--i-block {
  display: inline-block;
}

.socure-display--block {
  display: block;
}

.socure-display--inline {
  display: inline;
}

.socure-align--float-left {
  float: left;
}

.socure-align--float-right {
  float: right;
}

.socure-align--float-none {
  float: none;
}

.socure-cursor--pointer {
  cursor: pointer;
}

.socure-cursor--not-allowed {
  cursor: not-allowed;
}

.socure-position--relative {
  position: relative;
}

.socure-position--absolute {
  position: absolute;
}

.socure-position--fixed {
  position: absolute;
}

.socure-position--rounded-circle {
  border-radius: 50%;
}

.socure-text-no-select {
  user-select: none;
}

