.socure-c-left-navigation {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  background-color: transparent;
  border: none;
  transition: all linear 300ms;
}

@media screen and (max-width: 600px) {
  .socure-c-left-navigation {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.socure-c-left-navigation--expanded {
  width: 210px;
  box-shadow: 0 12px 32px rgba(29,42,68,0.1);
}

@media screen and (max-width: 600px) {
  .socure-c-left-navigation--expanded {
    width: 210px;
  }
}

.socure-c-left-navigation--collapsed {
  width: 48px;
  box-shadow: 0 4px 8px rgba(29,42,68,0.1);
}

@media screen and (max-width: 600px) {
  .socure-c-left-navigation--collapsed {
    display: none;
  }
}

.socure-c-left-navigation__header {
  display: inline-flex;
  flex-direction: row;
  height: 52px;
}

.socure-c-left-navigation__logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.socure-c-left-navigation__logo--expanded {
  margin-top: 16px;
  margin-left: 16px;
}

.socure-c-left-navigation__logo--collapsed {
  margin-top: 14px;
  margin-left: 16px;
}

.socure-c-left-navigation__controls {
  display: flex;
}

.socure-c-left-navigation__items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 86vh;
  margin-left: 8px;
}

.socure-c-left-navigation__items::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.socure-c-left-navigation__toggle {
  margin-top: 8px;
  display: flex;
}

.socure-c-left-navigation__headertext {
  color: var(--socure-global--color-white);
  font-size: var(--socure-global--FontSize--18);
  margin: 2px 0px 0px 10px;
}

.socure-c-left-navigation__mobile-toggle {
  display: none;
}

@media screen and (max-width: 600px) {
  .socure-c-left-navigation__mobile-toggle {
    display: flex;
  }
}

@media screen and (max-width: 600px) {
  .socure-c-left-navigation__toggle {
    display: none;
  }

  .socure-c-left-navigation__headertext {
    display: none;
  }
}

