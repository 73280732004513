.socure-c-chips-outer-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  height: 32px;
  padding: 8px 12px;
  cursor: pointer;
  background: var(--socure-global--chips-background);
  border-radius: 5000px;
}

.socure-c-chips-outer-container-selected {
  background: var(--socure-global--chips-selected-background);
}

.socure-c-chips-icon {
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
}

.socure-c-chips-inner-container {
  position: static;
  display: flex;
  overflow: hidden;
  font-size: var(--socure-global--FontSize--2xs);
  font-weight: var(--socure-global--FontWeight--normal);
  line-height: var(--socure-global--LineHeight-s);
  color: var(--socure-global--chips-text-color);
  letter-spacing: .4px;
  white-space: nowrap;
}

.socure-c-chips-inner-container-selected {
  font-weight: var(--socure-global--FontWeight--bold);
  color: var(--socure-global--color-white);
}

