.socure-c-card-portrait-container {
  display: flex;
  flex: 0 0 164px;
}

.socure-c-card-portrait-container>img {
  width: 144px;
  height: 144px;
  margin: 20px 33px 0;
  border-radius: 8px;
}

.socure-c-card-portrait-container-expanded>img {
  width: 135px;
  height: 135px;
  margin: 16px;
  border-radius: 8px;
}

.socure-c-card-detail-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin: 0 20px;
}

.socure-c-card-detail-container-expanded {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 20px;
}

.socure-c-card-detail-container .socure-c-card-name-container,
.socure-c-card-detail-container-expanded .socure-c-card-name-container {
  height: 54px;
}

.socure-c-card-detail-container .socure-c-card-updated-container,
.socure-c-card-detail-container .socure-c-card-decision-container,
.socure-c-card-detail-container-expanded .socure-c-card-updated-container,
.socure-c-card-detail-container-expanded .socure-c-card-decision-container {
  height: 36px;
}

.socure-c-card-detail-container .socure-c-card-decision-container,
.socure-c-card-detail-container-expanded .socure-c-card-decision-container {
  margin-bottom: 12px;
}

.socure-c-card-detail-container .socure-c-card-name-container,
.socure-c-card-detail-container .socure-c-card-updated-container,
.socure-c-card-detail-container .socure-c-card-decision-container,
.socure-c-card-detail-container-expanded .socure-c-card-name-container,
.socure-c-card-detail-container-expanded .socure-c-card-updated-container,
.socure-c-card-detail-container-expanded .socure-c-card-decision-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin-top: 12px;
}

.socure-c-card-detail-container .socure-c-card-data-container,
.socure-c-card-detail-container-expanded .socure-c-card-data-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;
}

.socure-c-card-detail-container .socure-c-card-data-container:first-child,
.socure-c-card-detail-container-expanded .socure-c-card-data-container:first-child {
  margin-top: 19px;
}

.socure-c-card-rounded-border {
  border-radius: 0 8px 8px 0;
}

.socure-c-card-entity-detail-header-container {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  padding-left: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: #22272f;
  letter-spacing: 0;
  cursor: pointer;
  background-color: #fff;
  border-radius: 0 4px 4px 0;
}

.socure-c-card-entity-detail-header-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
}

.socure-c-card-entity-detail-header-toggle.socure-c-card-toggle {
  transform: rotate(180deg);
}

.socure-c-card-entity-detail-header-toggle svg {
  cursor: pointer;
}

.socure-c-card-entity-detail-llm-panel {
  padding: 12px 0;
  background: #F3FEFC;
  border-radius: 8px;
  border: 1px solid var(--socure-global--color-decision-icon-color);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
}

.socure-c-card-entity-detail-top-bar {
  align-self: stretch;
  padding: 0 24px 0 16px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.socure-c-card-entity-detail-match-field {
  display: inline;
  font-weight: 600;
  font-size: var(--socure-global--FontSize--s);
  text-transform: none;
}

.socure-c-card-entity-detail-hide-details-button {
  margin-left: auto;
  color: #2B96B7;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
}

.socure-c-card-entity-detail-rationale-section {
  width: 100%;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.socure-c-card-entity-detail-action-section {
  align-self: stretch;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 16px;
}

.socure-c-card-entity-detail-agree-button {
  padding: 8px 16px;
  background: var(--socure-global--color-teal-button);
  color: #fff !important;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  font-weight: 500;
}

.socure-c-card-entity-detail-disagree-button {
  padding: 8px 16px;
  background: #fff;
  color: #3C3E45;
  border: 1px solid #3C3E45;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.socure-c-card-entity-detail-action-text-section {
  color: #222;
  font-size: var(--socure-global--FontSize--xs);
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.detail-panel {
  background: white;
  border-radius: 8px;
  border: 1px solid var(--socure-global--color-decision-icon-color);
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 16px;
}

.detail-panel .name-bar {
  border-radius: 24px;
  background: #F8FAFC;
  height: 40px;
  padding: 8px 20px;
  color: #113A5F;
  font-family: "Albert Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.detail-panel .top-bar {
  border-bottom: 1px solid var(--socure-global--color-switch-background-disabled);
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.detail-panel .top-bar .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.detail-panel .top-bar .header .icon {
  flex: 0 0 auto;
  position: relative;
}

.detail-panel .top-bar .header .icon .vector {
  position: absolute;
  width: 12px;
  height: 12px;
  background: #237791;
}

.detail-panel .top-bar .header .action-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.detail-panel .top-bar .header .action-details .title {
  font-size: 16px;
  font-family: var(--socure-global-font-family);
  font-weight: 700;
  color: black;
}

.detail-panel .top-bar .header .action-details .subtitle {
  font-size: 14px;
  font-family: var(--socure-global-font-family);
  font-weight: 400;
  color: black;
}

.detail-panel .top-bar .header .confidence-badge {
  background: #bcdae3;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 14px;
  font-family: var(--socure-global-font-family);
  font-weight: 700;
  color: #0e4758;
  text-align: center;
}

.detail-panel .top-bar .header .chevron {
  flex: 0 0 auto;
}

.detail-panel .top-bar .header .chevron .vector {
  width: 12px;
  height: 6px;
  border: 2px solid #121619;
  position: absolute;
}

.detail-panel .detail-panel-heading {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.detail-panel .detail-panel-text {
  color: #7C7C7C;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 6px;
}

.detail-panel .details-section .identity-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  padding-top: 8px;
}

.detail-panel .details-section .identity-details .identity-row {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1 1 0;
  padding-left: 8px;
}

.detail-panel .details-section .identity-details .identity-row .label {
  font-size: 14px;
  font-family: var(--socure-global-font-family);
  font-weight: 600;
  color: #858585;
  line-height: 1.4;
  text-align: left;
}

.detail-panel .details-section .identity-details .identity-row .value {
  font-size: 14px;
  font-family: var(--socure-global-font-family);
  font-weight: 400;
  color: #151515;
  line-height: 1.4;
  text-align: left;
  text-transform: capitalize;
}

.detail-panel .details-section .explanation-section {
  margin-top: 16px;
  margin-bottom: 16px;
}

.detail-panel .details-section .explanation-section .explanation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
}

.detail-panel .details-section .explanation-section .explanation-header .text {
  font-size: 14px;
  font-family: var(--socure-global-font-family);
  font-weight: 600;
}

.detail-panel .details-section .explanation-section .explanation-header .text span:first-child {
  color: black;
}

.detail-panel .details-section .explanation-section .explanation-header .icons {
  display: flex;
  gap: 8px;
}

.detail-panel .details-section .explanation-content {
  margin-bottom: 16px;
  padding-left: 8px;
}

.detail-panel .details-section .explanation-content .paragraph {
  font-size: 14px;
  font-family: var(--socure-global-font-family);
  font-weight: 400;
  color: #22272f;
  margin-bottom: 8px;
}

.detail-panel .details-section .explanation-content .learn-more {
  font-size: 14px;
  font-family: var(--socure-global-font-family);
  font-weight: 400;
  color: #2b96b7;
  text-decoration: underline;
  cursor: pointer;
}

.detail-panel .details-section .explanation-content .tags {
  display: flex;
  gap: 8px;
}

.detail-panel .details-section .explanation-content .tags .tag {
  padding: 4px 8px;
  background: white;
  border: 1px solid var(--socure-global--color-decision-icon-color);
  border-radius: 4px;
  font-size: 10px;
  font-family: var(--socure-global-font-family);
  font-weight: 700;
  text-transform: uppercase;
  color: #151a20;
}

.detail-panel .details-section .actions {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.detail-panel .details-section .actions .button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-family: var(--socure-global-font-family);
  font-weight: 600;
  cursor: pointer;
}

.detail-panel .details-section .actions .button.reject {
  background: white;
  border: 1px solid #d9d9d9;
  color: #121619;
}

.detail-panel .details-section .actions .button.reject .thumbs-down {
  width: 12px;
  height: 12px;
  border: 2px solid #121619;
}

.detail-panel .details-section .actions .button.accept {
  background: #237791;
  border: none;
  color: white;
}

.detail-panel .details-section .actions .button.accept .thumbs-up {
  width: 12px;
  height: 12px;
  border: 2px solid white;
}

.learn-more {
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 10px;
  margin-bottom: 10px;
}

.explainablity-scores {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: #F8FAFC;
  border-radius: 8px;
  height: auto;
}

.explainablity-details {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.explainablity-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 33%;
}

.explainablity-row .label {
  color: #22272F;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 22px;
  word-wrap: break-word;
}

.explainablity-row .value {
  display: flex;
  align-items: flex-end;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #0E4758;
}

.explainablity-row .value .number {
  font-size: 21px;
  line-height: 21.42px;
}

.explainablity-row .value .percentage {
  font-size: 12px;
  line-height: 12.24px;
  margin-left: 2px;
}

.llm-disclaimer {
  display: flex;
  align-items: center;
  gap: 4px;
}

.llm-disclaimer-text {
  font-size: 0.675rem;
  color: #4a4a4a;
}

.llm-learn-more {
  color: #67686b;
  cursor: pointer;
  text-decoration: underline;
}

.tooltip-content {
  padding: 8px;
  min-height: 200px;
  max-height: 600px;
  width: 18rem;
  overflow: auto;
  background-color: #ffffff;
  color: #000;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.carousel-container {
  position: relative;
  width: 52.563px;
  height: 57.405px;
  overflow: hidden;
}

.carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 76px;
  height: 83px;
  opacity: 0;
  animation: carouselCycle 5000ms infinite ease-out;
}

.carousel-item:nth-child(1) {
  animation-delay: 0ms;
}

.carousel-item:nth-child(2) {
  animation-delay: 714ms;
}

.carousel-item:nth-child(3) {
  animation-delay: 1428ms;
}

.carousel-item:nth-child(4) {
  animation-delay: 2142ms;
}

.carousel-item:nth-child(5) {
  animation-delay: 2856ms;
}

.carousel-item:nth-child(6) {
  animation-delay: 3570ms;
}

.carousel-item:nth-child(7) {
  animation-delay: 4284ms;
}

@keyframes carouselCycle {
  0%, 10% {
    opacity: 0;
  }

  15%, 25% {
    opacity: 1;
  }

  30%, 100% {
    opacity: 0;
  }
}

