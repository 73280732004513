section.socure-c-guageChart-container {
  width: 150px;
  height: 150px;
}

section.socure-c-guageChart-container .socure-c-guageChart-details {
  position: relative;
  top: -35px;
  text-align: center;
}

section.socure-c-guageChart-container .socure-c-guageChart-details h3 {
  margin: 5px;
  font-size: 12px;
  font-weight: 700;
  line-height: 19px;
  color: #26272c;
}

section.socure-c-guageChart-container .socure-c-guageChart-details p {
  padding: 0 20px;
  font-size: 13px;
  line-height: 16px;
  color: #54565a;
}

