.socure-c-condition-flow-container {
  position: relative;
  width: 100%;
  height: 287px;
  padding: 40px 100px;
  color: var(--socure-global--color-white);
  text-transform: uppercase;
  background: var(--socure-global--color-black-02);
  transition: .2s linear;
}

.socure-c-condition-flow-container .socure-c-condition-flow {
  display: flex;
  align-items: center;
}

.socure-c-condition-flow-container .socure-c-condition-rail-hr {
  position: absolute;
  top: 33%;
  right: 50px;
  left: 50px;
  height: 1.5px;
  background-color: var(--socure-global--color-green-80);
  transition: .2s linear;
}

.socure-c-condition-flow-container .socure-c-condition-collapse {
  position: absolute;
  top: 39px;
  right: 20px;
  width: 23px;
  height: 12px;
  cursor: pointer;
  transition: .2s linear;
  transform: rotate(180deg);
}

.socure-c-condition-flow-container.socure-c-condition-flow-collapse {
  height: 66px;
}

.socure-c-condition-flow-container.socure-c-condition-flow-collapse .socure-c-condition-components .socure-c-condition-flow>button {
  display: none;
  opacity: 0;
}

.socure-c-condition-flow-container.socure-c-condition-flow-collapse .socure-c-condition-components .socure-c-condition-rail-hr {
  top: -250px;
}

.socure-c-condition-flow-container.socure-c-condition-flow-collapse .socure-c-condition-components .socure-c-eachCondition,
.socure-c-condition-flow-container.socure-c-condition-flow-collapse .socure-c-condition-components>button {
  top: -250px;
}

.socure-c-condition-flow-container.socure-c-condition-flow-collapse .socure-c-failure-condition .socure-c-failure-condition-rail-hr,
.socure-c-condition-flow-container.socure-c-condition-flow-collapse .socure-c-failure-condition .socure-c-fail-btn {
  display: none;
}

.socure-c-condition-flow-container.socure-c-condition-flow-collapse .socure-c-failure-condition button::before {
  display: none;
}

.socure-c-condition-flow-container .socure-c-triangle-down {
  position: absolute;
  bottom: -10px;
  width: 0;
  height: 0;
  border-top: 12px solid #000;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}

.socure-c-condition-flow-container .socure-c-triangle-down.socure-c-triangle-down-reject {
  left: 278px;
}

.socure-c-condition-flow-container .socure-c-triangle-down.socure-c-triangle-down-refer {
  left: 540px;
}

.socure-c-condition-flow-container .socure-c-triangle-down.socure-c-triangle-down-resubmit {
  right: 610px;
}

.socure-c-condition-flow-container .socure-c-triangle-down.socure-c-triangle-down-review {
  right: 345px;
}

.socure-c-condition-components {
  position: relative;
  display: inline-block;
}

.socure-c-condition-components .socure-c-condition-flow {
  position: relative;
  display: flex;
  align-items: center;
}

.socure-c-condition-components .socure-c-condition-flow .socure-c-eachCondition {
  position: relative;
  top: -40px;
  display: flex;
  align-items: center;
  min-height: 230px;
  margin-left: 30px;
  transition: .2s linear;
}

.socure-c-condition-components .socure-c-condition-flow .socure-c-eachCondition .socure-c-pass-btn {
  display: inline-block;
  padding: 10px;
  margin-left: 30px;
  font-size: var(--socure-global--FontSize--3xs);
  color: var(--socure-global--color-green-80);
  background: var(--socure-global--color-black-02);
}

.socure-c-condition-components .socure-c-condition-flow>button {
  position: relative;
  top: -40px;
  text-transform: uppercase;
  cursor: default;
  background-color: var(--socure-global--color-black-02);
  opacity: 1;
  transition: .2s linear;
}

.socure-c-condition-components .socure-c-condition-flow>button:last-child::before {
  position: absolute;
  top: 51%;
  left: -6px;
  width: 8px;
  height: 8px;
  content: "";
  border-right: 1.5px solid var(--socure-global--color-green-80);
  border-bottom: 1.5px solid var(--socure-global--color-green-80);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.socure-c-condition-components button {
  background-color: var(--socure-global--color-black-02);
}

.socure-c-condition-components button:last-child {
  margin-left: 30px;
}

.socure-c-condition-components button:last-child::before {
  position: absolute;
  top: 51%;
  left: -7px;
  width: 8px;
  height: 8px;
  content: "";
  border-right: 1.5px solid var(--socure-global--color-green-80);
  border-bottom: 1.5px solid var(--socure-global--color-green-80);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.socure-c-eachCondition-container {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 80px;
  min-height: 235px;
}

.socure-c-eachCondition-container .socure-c-entry-condition {
  position: relative;
  z-index: 1;
  display: block;
  min-width: 160px;
  max-width: 160px;
  margin: 0 auto;
}

.socure-c-eachCondition-container .socure-c-condition-list-container {
  max-height: 144px;
  padding: 8px;
  overflow: auto;
  background: var(--socure-global--color-decision-outcome-background);
  border: 1px solid var(--socure-global--color-decision-group-container-border);
  border-radius: 8px;
}

.socure-c-eachCondition-container .socure-c-condition-list-container li {
  padding: 0 8px;
  font-size: var(--socure-global--LineHeight-2xs);
  line-height: var(--socure-global--LineHeight-24);
  color: var(--socure-global--color-decision-list-color);
  cursor: pointer;
  border-radius: 30px;
  transition: .1s linear;
}

.socure-c-eachCondition-container .socure-c-condition-list-container li:hover,
.socure-c-eachCondition-container .socure-c-condition-list-container li .socure-c-each-condition-tag-active {
  color: var(--socure-global--color-decision-border-color);
  background: var(--socure-global--color-decision-collapsible-background);
  border-radius: 30px;
}

.socure-c-eachCondition-container .socure-c-condition-list-container::before {
  position: absolute;
  top: 50.6%;
  left: -5px;
  width: 8px;
  height: 8px;
  content: "";
  border-right: 1.5px solid var(--socure-global--color-green-80);
  border-bottom: 1.5px solid var(--socure-global--color-green-80);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.socure-c-eachCondition-container .socure-c-failure-condition {
  position: absolute;
  bottom: -35px;
  width: 100%;
  text-align: center;
}

.socure-c-eachCondition-container .socure-c-failure-condition .socure-c-failure-condition-rail-hr {
  position: absolute;
  top: -55px;
  bottom: 10px;
  width: 1.5px;
  background-color: var(--socure-global--color-red-120);
}

.socure-c-eachCondition-container .socure-c-failure-condition .socure-c-fail-btn {
  position: relative;
  bottom: -5px;
  display: block;
  margin: 15px 10px 5px;
  font-size: var(--socure-global--LineHeight-3xs);
  color: var(--socure-global--color-red-120);
  text-align: center;
  background: var(--socure-global--color-black-02);
}

.socure-c-eachCondition-container .socure-c-failure-condition button {
  position: relative;
  margin-top: 15px;
  margin-left: 0;
}

.socure-c-eachCondition-container .socure-c-failure-condition button::before {
  position: absolute;
  top: -7px;
  left: 50.5%;
  width: 8px;
  height: 8px;
  content: "";
  border-right: 1.5px solid var(--socure-global--color-red-120);
  border-bottom: 1.5px solid var(--socure-global--color-red-120);
  transform: translate(-50%, -50%) rotate(45deg);
}

.socure-c-eachCondition:not(.socure-c-currentConditionModule) .socure-c-eachCondition-container .socure-c-entry-condition {
  pointer-events: none;
}

.socure-c-eachCondition:not(.socure-c-currentConditionModule) .socure-c-condition-list-container .socure-c-each-condition-tag-active {
  background: transparent;
}

.socure-c-each-condition-tag-refer {
  color: var(--socure-global--color-yellow-60) !important;
}

.socure-c-each-condition-tag-reject {
  color: var(--socure-global--alert-error-color) !important;
}

.socure-c-failure-condition-active-button .socure-c-button--transparent {
  background-color: transparent;
  border: solid 3px var(--socure-global--color-teal-button) !important;
}

