.socure-c-tipoff-wrapper {
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  margin-top: 10px;
}

.socure-c-Tipoff-container {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  min-width: 0;
  height: 40px;
  border: 1px solid var(--socure-global--color-tipoff-border);
  border-radius: 4px;
  justify-items: flex-end;
}

.socure-c-Tipoff-input {
  position: relative;
  box-sizing: border-box;
  flex: 1;
  padding-left: 10px;
  margin: 3px;
  font-size: 16px;
  background-color: transparent;
  border: none;
}

.socure-c-Tipoff-placeholder {
  display: none;
}

.socure-c-Tipoff-placeholder-aft-selection {
  display: flex;
  flex: 1;
  align-self: flex-start;
  width: 100%;
  min-width: 0;
  overflow-x: hidden;
}

.socure-c-tipoff-filter-input-container {
  margin: 8px 0;
}

.socure-c-Tipoff-icon {
  position: relative;
  justify-content: flex-end;
  margin-top: 16px;
  margin-right: 24px;
}

.socure-c-Tipoff-popper-wrapper {
  position: absolute;
  top: 42px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  min-width: 300px;
  max-height: 240px;
}

.socure-c-Tipoff-popper-wrapper>div {
  position: relative !important;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow-x: hidden;
  transform: none !important;
}

.socure-c-Tipoff-popper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  text-overflow: ellipsis;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(29,42,68,0.1);
  align-content: flex-start;
}

.socure-c-Tipoff-Item-wrapper {
  display: flex;
  flex: 1;
  align-items: flex-start;
  min-width: 0;
  padding-left: 16px;
}

.socure-c-Tipoff-Item-wrapper>div {
  flex: 1 1 auto;
  min-width: 0;
}

.socure-c-Tipoff-filtered-Item {
  display: flex;
  align-items: flex-start;
  padding-right: 10px;
  padding-left: 10px;
}

.socure-c-Tipoff-filtered-Item>div {
  flex: 1 1 auto;
}

.socure-c-Tipoff-input-show {
  display: flex;
}

.socure-c-Tipoff-input-hide {
  display: none;
}

.socure-c-Tipoff-icon-hide {
  visibility: hidden;
}

.socure-c-Tipoff-icon-show {
  visibility: visible;
}

.socure-c-Tipoff-placeholder-hide {
  display: none;
}

.socure-c-Tipoff-placeholder-show {
  display: flex;
}

.socure-c-selected-label-container {
  width: 100%;
}

.socure-c-selected-label-icon-container {
  display: flex;
  margin-left: 4px;
  cursor: pointer;
}

.socure-c-option-cb-container {
  padding-right: 16px;
}

