.socure-c-decision-container {
  min-width: 0;
  padding-right: 48px;
}

.socure-c-db-each-decision.socure-c-dv-new-decsion {
  margin-right: 38px;
}

.socure-c-collapsible-wdd-row {
  display: flex;
  flex: 1;
  align-items: flex-start;
  min-width: 0;
  background-color: var(--socure-global--color-white);
  border: 1px solid var(--socure-global--color-gray-60);
  border-radius: 4px;
}

.socure-c-collapsible-wdd-row:hover {
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: 0 4px 16px #1d2a441a;
}

.socure-c-collapsible-wdd-row.socure-c-collapsible-wdd-invalid-row {
  border: 1px solid var(--socure-global--color-red-60);
}

.socure-c-collapsible-wdd-row.socure-c-collapsible-wdd-hidden {
  opacity: 0;
}

.socure-c-collapsible-wdd-container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  min-width: 0;
}

.socure-c-collapsible-wdd-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 8px;
  font-family: var(--socure-global-font-family);
  font-size: var(--socure-global--FontSize--m);
  line-height: var(--socure-global--LineHeight-28);
  color: var(--socure-global--color-black);
  background-color: transparent;
  border: none;
  outline: none;
}

.socure-c-collapsible-wdd-content {
  padding: 24px 16px;
  transition: all .2s ease-out;
}

.socure-c-collapsible-wdd-content.socure-c-collapsible-wdd-show {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
}

.socure-c-collapsible-wdd-content.socure-c-collapsible-wdd-hide {
  display: none;
}

.socure-c-collapsible-wdd-title-container {
  display: flex;
  align-items: center;
}

.socure-c-collapsible-wdd-title {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.socure-c-collapsible-wdd-selected-color {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.socure-c-collapsible-wdd-selected-color.socure-c-collapsible-wdd-reject {
  background-color: var(--socure-global--color-orange-80);
}

.socure-c-collapsible-wdd-selected-color.socure-c-collapsible-wdd-refer {
  background-color: var(--socure-global--color-orange-100);
}

.socure-c-collapsible-wdd-selected-color.socure-c-collapsible-wdd-resubmit {
  background-color: var(--socure-global--color-yellow-80);
}

.socure-c-collapsible-wdd-selected-color.socure-c-collapsible-wdd-review {
  background-color: var(--socure-global--color-yellow-60);
}

.socure-c-collapsible-wdd-selected-color.socure-c-collapsible-wdd-accept {
  background-color: var(--socure-global--color-green-60);
}

.socure-c-collapsible-wdd-new-decision-title {
  font-family: var(--socure-global-font-family);
  font-size: var(--socure-global--FontSize--m);
  line-height: var(--socure-global--LineHeight-24);
  color: var(--socure-global--color-gray-60);
}

.socure-c-collapsible-wdd-header {
  font-family: var(--socure-global-font-family);
  font-size: var(--socure-global--FontSize--xs);
  line-height: var(--socure-global--LineHeight-24);
  color: var(--socure-global--color-gray-40);
}

.socure-c-collapsible-wdd-secondary-header {
  margin-top: 16px;
  font-family: var(--socure-global-font-family);
  font-size: var(--socure-global--FontSize--xs);
  font-weight: 600;
  line-height: var(--socure-global--LineHeight-24);
  color: var(#000);
}

.socure-c-collapsible-wdd-header-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
}

.socure-c-db-tag-label {
  font-family: var(--socure-global-font-family);
  font-size: var(--socure-global--FontSize--3xs);
  font-weight: 600;
  line-height: var(--socure-global--LineHeight-14);
  color: var(--socure-global--color-gray-40);
  text-transform: uppercase;
  letter-spacing: .06em;
}

.socure-c-ed-collapsible-container {
  position: relative;
  display: flex;
  flex: 1;
  min-width: 0;
}

.socure-c-new-decision-trigger-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.socure-c-new-decision-label {
  margin-left: 4px;
  font-size: var(--socure-global--FontSize--2xs);
  font-weight: var(--socure-global--FontWeight--normal);
  line-height: var(--socure-global--LineHeight-14);
  color: var(--socure-global--TextColor--white);
  text-transform: uppercase;
}

