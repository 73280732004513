.socure-c-pills-outer-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  height: 22px;
  padding: 4px 8px;
  border-radius: 4px;
}

.socure-c-pills-inner-container {
  position: static;
  overflow: hidden;
  font-size: var(--socure-global--FontSize--3xs);
  font-weight: var(--socure-global--FontWeight--bold);
  line-height: var(--socure-global--LineHeight-xs);
  text-transform: uppercase;
  font-feature-settings: "case" on;
  letter-spacing: .06em;
  white-space: nowrap;
}

.socure-c-pills-high-text-color {
  color: var(--socure-global--color-red-20);
}

.socure-c-pills-high-background {
  background: var(--socure-global--color-red-100);
}

.socure-c-pills-medium-text-color {
  color: var(--socure-global--color-yellow-20);
}

.socure-c-pills-medium-background {
  background: var(--socure-global--color-yellow-100);
}

.socure-c-pills-low-text-color {
  color: var(--socure-global--color-green-20);
}

.socure-c-pills-low-background {
  background: var(--socure-global--color-green-100);
}

.socure-c-pills-neutral-text-color {
  color: var(--socure-global--color-cyan-20);
}

.socure-c-pills-neutral-background {
  background: var(--socure-global--color-cyan-100);
}

.socure-c-pills-info-text-color {
  color: var(--socure-global--color-gray-20);
}

.socure-c-pills-info-border {
  border: 1px solid var(--socure-global--color-info-pill-outline-border);
}

.socure-c-pills-error-text-color {
  color: var(--socure-global--color-red-60);
}

.socure-c-pills-error-background {
  border: 1px solid var(--socure-global--color-red-100);
}

.socure-c-pills-attribute-text-color {
  color: var(--socure-global--color-blue-20);
}

.socure-c-pills-attribute-background {
  background: var(--socure-global--color-gray-80);
}

.socure-c-pills-reasoncode-text-color {
  color: var(--socure-global--color-black);
}

.socure-c-pills-reasoncode-background {
  background: var(--socure-global--color-decision-reasoncode-pill);
}

