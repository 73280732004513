.socure-c-lb-sub-group {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 12px;
}

.socure-c-lb-empty-sub-group {
  background: var(--socure-global--color-decision-highlight-condition);
}

.socure-c-lb-each-row {
  display: flex;
  flex-direction: column;
}

.socure-c-lb-each-row:hover,
.socure-c-lb-each-row:focus {
  cursor: grab;
}

.socure-c-lb-group-row {
  position: relative;
  padding: 12px 0;
  margin-left: 16px;
}

.socure-c-lb-group-row::before {
  position: absolute;
  bottom: 0;
  left: -2px;
  display: block;
  height: 100%;
  content: "";
  border-left: 2px solid var(--socure-global--color-decision-condition-ellipse);
}

.socure-c-lb-group-row.socure-c-lb-first-group::before {
  bottom: 0;
  height: 50%;
}

.socure-c-lb-group-row.socure-c-lb-last-group::before {
  top: 0;
  height: 50%;
}

.socure-c-lb-group-row.socure-c-lb-single-group::before {
  top: 0;
  height: 0;
}

