.socure-c-option-group {
  display: flex;
  flex-direction: column;
}

.socure-c-option-group__header {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 40px;
  vertical-align: middle;
}

.socure-c-option-group__header:hover,
.socure-c-option-group__header:active {
  background-color: var(--socure-global--BackgroundColor--gray);
}

.socure-c-option-group__option-container>div>:first-child {
  margin-left: 40px;
}

.socure-c-option-group__option-container--expanded {
  display: flex;
  flex-direction: column;
}

.socure-c-option-group__option-container--collapsed {
  display: none;
}

.socure-c-option-container {
  display: inline-flex;
  flex: 1;
  align-items: center;
  min-width: 0;
  padding: 8px 0;
}

.socure-c-option-container:hover,
.socure-c-option-container:active {
  background-color: transparent;
}

