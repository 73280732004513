.socure-c-alert__container-success,
.socure-c-alert__container-information,
.socure-c-alert__container-error {
  box-sizing: border-box;
  display: inline-flex;
  width: 320px;
  min-height: 114px;
  font-family: var(--socure-global-font-family);
  background: var(--socure-global--color-white);
  border: 1px solid var(--socure-global--color-gray-80);
  border-radius: 4px;
  box-shadow: 0 32px 64px rgba(44,9,12,0.1);
}

.socure-c-alert__container-timeout {
  box-sizing: border-box;
  display: inline-flex;
  width: 320px;
  min-height: 114px;
  font-family: var(--socure-global-font-family);
  background: var(--socure-global--color-white);
  border: 1px solid var(--socure-global--color-gray-80);
  border-radius: 4px;
  box-shadow: 0 32px 64px rgba(44,9,12,0.1);
}

.socure-c-alert__frame-0 {
  margin-top: 12px;
}

.socure-c-alert__frame-1 {
  margin-right: 12px;
}

.socure-c-alert__frame-1,
.socure-c-alert__frame-2 {
  display: flex;
  flex-direction: column;
}

.socure-c-alert__frame-3 {
  display: flex;
  flex: 0 0 46px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.socure-c-alert__action {
  font-size: var(--socure-global--FontSize--3xs);
  font-style: var(--socure-global--FontStyle--normal);
  font-weight: var(--socure-global--FontWeight--bold);
  line-height: var(--socure-global--LineHeight-14);
  text-transform: uppercase;
  cursor: pointer;
}

.socure-c-alert__action:not(:first-child) {
  margin-left: 16px;
}

.socure-c-alert__action-primary {
  color: var(--socure-global--color-teal-button);
}

.socure-c-alert__action-secondary {
  color: var(--socure-global--color-gray-60);
}

.socure-c-alert__label-information {
  top: 0;
  right: 1px;
  left: 0;
  flex: 0 0 4px;
  background: var(--socure-global--alert-information-color);
}

.socure-c-alert__label-success {
  top: 0;
  right: 1px;
  left: 0;
  flex: 0 0 4px;
  background: var(--socure-global--color-green-60);
}

.socure-c-alert__label-error {
  top: 0;
  right: 1px;
  left: 0;
  flex: 0 0 4px;
  background: var(--socure-global--color-red-60);
}

.socure-c-alert__label-timeout {
  top: 0;
  right: 1px;
  left: 0;
  flex: 0 0 4px;
  background: var(--socure-global--color-yellow-60);
}

.socure-c-alert__icon-case {
  display: flex;
  flex: 0 0 56px;
  justify-content: center;
  margin-top: 2px;
}

.socure-c-alert__icon {
  border: 2px solid var(--socure-global--color-green-60);
}

.socure-c-alert__title {
  display: flex;
  align-self: flex-start;
  font-family: var(--socure-global-font-family);
  font-size: var(--socure-global--FontSize--xs);
  font-style: var(--socure-global--FontStyle--normal);
  font-weight: var(--socure-global--FontWeight--bold);
  line-height: var(--socure-global--LineHeight-24);
  color: var(--socure-global--color-black);
}

.socure-c-alert__content {
  margin-top: 3px;
  font-family: var(--socure-global-font-family);
  font-size: var(--socure-global--FontSize--2xs);
  font-style: var(--socure-global--FontStyle--normal);
  font-weight: var(--socure-global--FontWeight--normal);
  line-height: var(--socure-global--LineHeight-18);
  color: var(--socure-global--color-gray-40);
}

