.socure-c-collapsible-container {
  width: 100%;
  font-size: 14px;
  font-weight: 800;
  line-height: 24px;
  color: var(--socure-global--color-black);
}

.socure-c-collapsible-button {
  display: flex;
  width: 100%;
  padding: 0;
  font-weight: var(--socure-global--FontWeight--bold);
  background-color: transparent;
  border: none;
  outline: none;
  align-items: center;
}

.socure-c-collapsible-button.socure-c-collapsible-button-space {
  justify-content: space-between;
}

.socure-c-collapsible-button.socure-c-collapsible-button-focused {
  border: 2px solid var(--socure-global--button-focus-border-color);
  padding-left: 2px;
}

.socure-c-collapsible-content {
  margin-top: 16px;
  transition: all .2s ease-out;
}

.socure-c-collapsible-content.socure-c-collapsible-show {
  display: block;
}

.socure-c-collapsible-content.socure-c-collapsible-hide {
  display: none;
}

