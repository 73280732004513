.socure-searchComponent {
  box-sizing: border-box;
  width: 100%;
  height: var(--socure-global--spacer--xl);
  padding: 6px 12px 7px 32px;
  font-size: var(--socure-global--FontSize--xs);
  line-height: var(--socure-global--LineHeight-18);
  border: solid 1px var(--socure-global--color-text-box-border);
  border-radius: var(--socure-global--BorderRadius--m);
}

.socure-searchComponent:focus:not(:disabled) {
  outline: 2px solid var(--socure-global--color-teal-outline-input);
}

.socure-searchComponent-button {
  padding: 6px 55px 7px 24px;
}

.socure-searchComponent-dark {
  color: var(--socure-global--color-white);
  background: var(--socure-global--color-search-dark-background);
}

.socure-search {
  width: 220px;
}

.socure-expanded-search {
  width: 100%;
}

.socure-searchComponent::placeholder {
  color: var(--socure-global--color-text-box-placeholder);
}

.socure-searchComponent:disabled {
  cursor: not-allowed;
  opacity: .4;
}

.socure-relative {
  position: relative;
}

.socure-absolute {
  position: absolute;
}

.socure-search-icon {
  top: 6px;
  left: 12px;
}

.socure-search-button-icon {
  top: 6px;
  right: 12px;
  display: flex;
  cursor: pointer;
}

.socure-line-div {
  align-self: center;
  height: 20px;
  padding-right: 12px;
  border-left: 1.5px solid #c3c4c5;
}

.socure-search-disabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: transparent;
  opacity: .5;
}

.socure-clear-button-icon {
  top: 4px;
  right: 45px;
  cursor: pointer;
}

.socure-clear-icon {
  top: 4px;
  right: 8px;
  cursor: pointer;
}

