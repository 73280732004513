.socure-c-tc-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  height: 100%;
  background-color: var(--socure-global--color-white);
}

.socure-c-tc-card-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  height: 100%;
}

.socure-c-tc-card-container #tab-container-button {
  margin-left: 24px;
  margin-bottom: 16px;
}

.socure-c-tc-left-nav-container {
  display: flex;
  flex: 0 0 135px;
  flex-direction: column;
  border-right: 1px solid var(--socure-global--color-tab-container-border);
}

.socure-c-tc-each-option {
  position: relative;
  display: flex;
  flex: 0 0 32px;
  align-items: center;
  padding-left: 16px;
  margin: 8px 0;
  font-family: var(--socure-global-font-family);
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}

.socure-c-tc-each-option:first-child {
  margin-top: 0;
}

.socure-c-tc-each-option:last-child {
  margin-bottom: 0;
}

.socure-c-tc-each-option.socure-c-tc-selected-option {
  font-weight: var(--socure-global--FontWeight--bold);
}

.socure-c-tc-each-option .socure-c-tc-selected-indicator {
  position: absolute;
  top: 0;
  right: -1px;
  width: 3px;
  height: 100%;
  background-color: var(--socure-global--color-orange-60);
}

.socure-c-tc-card-each-option {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 14px 10px 12px 12px;
  width: 252px;
  height: 79px;
  margin: 0 24px 16px 24px;
  cursor: pointer;
  background-color: var(--socure-global--color-content-background);
  border-radius: 8px;
}

.socure-c-tc-card-each-option:first-child {
  margin-top: 16px;
}

.socure-c-tc-card-each-option.socure-c-tc-card-selected-option {
  margin-right: 0;
  border-radius: 8px 0px 0px 8px;
  margin-left: 48px;
}

.socure-c-tc-card-each-option .socure-c-tc-card-content-disabled-pill {
  margin-top: -6px;
}

.socure-c-tc-card-each-option .socure-c-tc-card-content-disabled-pill .socure-c-pills-outer-container {
  height: 17px;
}

.socure-c-tc-card-each-option .socure-c-tc-card-content-header {
  color: #222;
  font-family: var(--socure-global-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.socure-c-tc-card-each-option .socure-c-tc-card-content-footer {
  color: #888;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
}

.socure-c-tc-content-container {
  display: flex;
  flex: 1 1 auto;
  overflow-y: auto;
}

.socure-c-tc-card-content-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  background-color: var(--socure-global--color-content-background);
}

.socure-c-tc-level-seperator {
  height: 32px;
  border-left: 1px solid var(--socure-global--color-menu-icon-dull);
  margin-left: 0.60rem;
  display: flex;
}

.socure-c-tc-pointer-events-none {
  pointer-events: none;
}

.socure-c-tc-cursor-pointer {
  cursor: pointer;
}

