.socure-c-timeline {
  position: relative;
  margin-left: 1px;
  display: flex;
  flex-flow: column wrap;
  align-content: normal;
  align-items: normal;
  justify-content: normal;
}

.socure-c-timeline::after {
  position: absolute;
  top: 10px;
  bottom: 0;
  width: 1px;
  margin-left: 3.5px;
  content: "";
  background-color: #999;
}

.socure-c-timeline-item__container {
  background-color: #fff;
}

.socure-c-timeline-item__container:hover {
  background-color: #eee;
}

.socure-c-timeline-item {
  position: relative;
  margin-bottom: 44px;
  margin-left: 14px;
}

.socure-c-timeline-item::after {
  position: absolute;
  top: 3px;
  left: -15px;
  z-index: 1;
  width: 10px;
  height: 10px;
  content: "";
  background-color: #fff;
  border: 2px solid #333;
  border-radius: 50%;
}

.socure-c-timeline-item__h2 {
  margin-bottom: 7px;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  color: #22272f;
  letter-spacing: .1px;
}

.socure-c-timeline-item__content {
  display: flex;
  flex-flow: column wrap;
  align-content: normal;
  align-items: normal;
  justify-content: normal;
}

