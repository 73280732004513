.socure-c-checkbox-container {
  display: flex;
  justify-content: left;
  font-size: var(--socure-global--FontSize--2xs);
  cursor: pointer;
}

.socure-c-checkbox-container .socure-c-checkbox {
  display: flex;
  align-self: center;
  width: 16px;
  height: 16px;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}

.socure-c-checkbox-container .socure-c-checkbox.socure-c-state-unchecked {
  border-color: var(--socure-global--color-text-input-border-2);
}

.socure-c-checkbox-container .socure-c-checkbox.socure-c-state-checked {
  justify-content: center;
  background-color: var(--socure-global--color-teal-border);
  border-color: var(--socure-global--color-teal-border);
}

.socure-c-checkbox-container .socure-c-checkbox.socure-c-state-disabled {
  border-color: var(--socure-global--cb-disabled-border);
}

.socure-c-checkbox-container .socure-c-checkbox.socure-c-state-disabled.socure-c-state-checked,
.socure-c-checkbox-container .socure-c-checkbox.socure-c-state-disabled .socure-c-state-indeterminate {
  background-color: var(--socure-secondary-button-hover);
}

.socure-c-checkbox-container .socure-c-checkbox .socure-c-checkbox-icon {
  display: flex;
  align-self: center;
}

.socure-c-checkbox-container .socure-c-checkbox .socure-c-checkbox-icon.socure-c-state-indeterminate {
  width: 6.5px;
  height: 2px;
  background-color: var(--socure-global--color-white);
  border-radius: 2px;
}

.socure-c-checkbox-container .socure-c-checkbox-label {
  align-self: center;
  margin-left: 8px;
  font-size: var(--socure-global--FontSize--xs);
  line-height: var(--socure-global--LineHeight-20);
  color: var(--socure-global--color-text-input-border-2);
}

.socure-c-checkbox-container .socure-c-checkbox-label.socure-c-checkbox-label-checked {
  font-weight: 600;
}

.socure-c-checkbox-container:hover .socure-c-checkbox:not(.socure-c-state-disabled).socure-c-state-unchecked {
  border-color: var(--socure-global--color-teal-border);
}

.socure-c-checkbox-container:hover .socure-c-checkbox:not(.socure-c-state-disabled).socure-c-state-checked {
  background-color: var(--socure-global--color-teal-border);
  border-color: var(--socure-global--color-teal-border);
}

.socure-c-checkbox-container:focus-within .socure-c-checkbox:not(.socure-c-state-disabled) {
  outline: 2px solid var(--socure-global--color-teal-outline-input);
  border-color: var(--socure-global--color-teal-border);
}

.socure-c-checkbox-container:focus-within .socure-c-checkbox:not(.socure-c-state-disabled).socure-c-state-checked {
  background-color: var(--socure-global--color-teal-border);
}

.socure-c-checkbox-container:focus-within .socure-c-checkbox-label.socure-c-checkbox-label-checked {
  font-weight: 700;
}

