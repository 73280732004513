.socure-c-calendar {
  margin-right: 27.33px;
  margin-bottom: 14px;
  margin-left: 27.33px;
}

.socure-c-calendar-header {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}

.socure-c-calendar-arrow-left-container {
  margin-left: 27.33px;
}

.socure-c-calendar-arrow-right-container {
  margin-right: 27.33px;
}

.socure-c-calendar-current-date {
  display: flex;
  align-items: center;
  font-size: var(--socure-global--FontSize--2xs);
  font-style: normal;
  font-weight: var(--socure-global--FontWeight--bold);
  line-height: var(--socure-global--LineHeight-18);
  color: var(--socure-global--color-black);
}

.socure-c-calendar-year {
  margin-left: 5px;
}

.socure-c-calendar-day-container {
  display: flex;
}

.socure-c-calendar-month-container {
  display: flex;
}

.socure-c-calendar-year-container {
  display: flex;
}

.socure-c-calendar-cell-container {
  display: flex;
}

.socure-c-calendar-day {
  width: 16px;
  height: 18px;
  margin-right: 8px;
  margin-left: 8px;
  font-size: var(--socure-global--FontSize--2xs);
  font-style: normal;
  font-weight: var(--socure-global--FontWeight--bold);
  line-height: var(--socure-global--LineHeight-18);
  color: var(--socure-global--color-black);
  text-align: center;
}

.socure-c-calendar-day:first-child {
  margin-left: 0;
}

.socure-c-calendar-day:last-child {
  margin-right: 0;
}

.socure-c-calendar-month-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: 8px 12px;
  font-size: var(--socure-global--FontSize--2xs);
  font-style: normal;
  font-weight: var(--socure-global--FontWeight--light);
  line-height: var(--socure-global--LineHeight-18);
  color: var(--socure-global--color-gray-20);
  text-align: center;
  cursor: pointer;
}

.socure-c-calendar-month-cell:hover {
  background: var(--socure-global--color-gray-80);
}

.socure-c-calendar-year-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: 8px 12px;
  font-size: var(--socure-global--FontSize--2xs);
  font-style: normal;
  font-weight: var(--socure-global--FontWeight--light);
  line-height: var(--socure-global--LineHeight-18);
  color: var(--socure-global--color-gray-20);
  text-align: center;
  cursor: pointer;
}

.socure-c-calendar-year-cell:hover {
  background: var(--socure-global--color-gray-80);
}

.socure-c-calendar-cell {
  width: 16px;
  max-width: 16px;
  height: 18px;
  max-height: 18px;
  margin: 4px 8px;
  font-size: var(--socure-global--FontSize--2xs);
  font-style: normal;
  font-weight: var(--socure-global--FontWeight--light);
  line-height: var(--socure-global--LineHeight-18);
  color: var(--socure-global--color-gray-20);
  text-align: center;
  cursor: pointer;
}

.socure-c-calendar-cell:first-child {
  margin-left: 0;
}

.socure-c-calendar-cell:last-child {
  margin-right: 0;
}

.socure-c-calendar-date:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin: -4px;
  background: var(--socure-global--color-gray-80);
}

.socure-c-calendar-cell-disabled {
  color: var(--socure-global--color-gray-60);
  pointer-events: none;
}

.socure-c-calendar-cell-active {
  color: var(--socure-global--color-orange-60);
}

