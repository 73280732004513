.socure-c-flabel__outer-container {
  position: relative;
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
}

.socure-c-flabel__inner-container {
  position: relative;
  align-items: flex-end;
  width: 100%;
  height: 56px;
}

.socure-c-flabel__fields-group {
  position: relative;
  display: inline-flex;
  align-items: flex-end;
  width: 100%;
  height: 44px;
  padding: 12px;
  margin-top: 12px;
  line-height: var(--socure-global--LineHeight-24);
  background-color: var(--socure-global--color-white);
  border: 1px solid var(--socure-global--color-text-input-border-2);
  color: var(--socure-global--color-text-input-border-2);
  border-radius: 4px;
}

.socure-c-flabel__fields-group:not(.socure-c-flabel__fields-group-error):focus-within {
  border: 1px solid var(--socure-global--color-teal-border);
  outline: 2px solid var(--socure-global--color-teal-outline-input);
}

.socure-c-flabel__fields-group:not(.socure-c-flabel__fields-group-error):focus-visible {
  border: 1px solid var(--socure-global--color-teal-border);
  outline: 2px solid var(--socure-global--color-teal-outline-input);
}

.socure-c-flabel__fields-group.socure-c-flabel__fields-group-error:focus-within {
  outline: 2px solid var(--socure-global--color-text-input-outline-error);
}

.socure-c-flabel__fields-group.socure-c-flabel__fields-group-error:focus-visible {
  outline: 2px solid var(--socure-global--color-text-input-outline-error);
}

.socure-c-flabel__fields-group:focus-within ~ .socure-c-flabel__label,
.socure-c-flabel__fields-group:focus-within ~ .socure-c-phInput__label,
.socure-c-flabel__fields-group:focus-visible ~ .socure-c-flabel__label,
.socure-c-flabel__fields-group:focus-visible ~ .socure-c-phInput__label {
  top: 0;
  left: 12px;
  padding: 2px 4px;
  font-size: var(--socure-global--FontSize--2xs);
  font-weight: var(--socure-global--FontWeight--bold);
  line-height: var(--socure-global--LineHeight-24);
  color: var(--socure-global--color-switch-label-disabled) !important;
  background-color: var(--socure-global--color-white);
}

.socure-c-flabel__fields-group:not(:focus-within) ~ .socure-c-flabel__label,
.socure-c-flabel__fields-group:not(:focus-within) ~ .socure-c-phInput__label {
  color: var(--socure-global--color-gray-40);
}

.socure-c-flabel__fields-group-error {
  border: 1px solid var(--socure-global--color-text-input-error);
}

.socure-c-flabel__fields-group-success {
  border: 1px solid var(--socure-global--color-text-input-border-2);
}

.socure-c-flabel__label,
.socure-c-phInput__label {
  position: absolute;
  top: 24px;
  left: 12px;
  font-size: var(--socure-global--FontSize--2xs);
  font-weight: var(--socure-global--FontWeight--bold);
  color: var(--socure-global--color-gray-40);
  text-transform: uppercase;
  transition: 300ms;
  transition-property: top, font-size;
}

.socure-c-flabel__label-active {
  top: 0;
  left: 12px !important;
  padding: 2px 4px;
  font-size: var(--socure-global--FontSize--2xs);
  font-weight: var(--socure-global--FontWeight--bold);
  line-height: var(--socure-global--LineHeight-24);
  color: var(--socure-global--color-switch-label-disabled);
  background-color: var(--socure-global--color-white);
}

.socure-c-flabel__label-success {
  color: var(--socure-global--color-gray-40) !important;
}

.socure-c-flabel__label-disabled {
  color: var(--socure-global--color-gray-60);
}

.socure-c-flabel__disabled {
  background-color: var(--socure-secondary-button-hover);
  border: 1px solid var(--socure-secondary-button-hover);
}

.socure-c-phInput-inputField {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: var(--socure-global--FontSize--xs);
  line-height: var(--socure-global--LineHeight-24);
  border: 0;
  outline: none;
}

.socure-c-phInput-inputField:focus {
  color: var(--socure-global--color-black);
  outline: none;
}

.socure-c-phInput-inputField[type="number"] {
  appearance: textfield;
}

.socure-c-phInput-inputField::-webkit-outer-spin-button,
.socure-c-phInput-inputField::-webkit-inner-spin-button {
  appearance: none;
}

.socure-c-phInput-validation-img {
  position: absolute;
  top: 9px;
  right: 16px;
}

.socure-c-phInput-disabled-input {
  pointer-events: none;
  background-color: var(--socure-global--color-gray-90);
  border: 1px solid var(--socure-global--color-gray-90);
}

.socure-c-phInput-validation-error-label {
  position: absolute;
  top: 56px;
  left: 0;
  margin-top: 4px;
  font-size: 14px;
  line-height: var(--socure-global--LineHeight-16);
  color: var(--socure-global--color-text-input-error);
}

.socure-c-phInput-validation-error-label .error-feather-icon {
  display: inline-block;
  height: 16px;
  line-height: 14px;
  margin-right: 5px;
  vertical-align: middle;
}

.socure-c-phInput-validation-error-label>.socure-c-tooltip-icon {
  margin-left: 8px;
}

.socure-c-phInput__country-code {
  margin: 0 8px;
  font-size: var(--socure-global--FontSize--s);
  font-weight: var(--socure-global--FontWeight--bold);
}

.socure-c-phInput__label {
  left: 75px;
  text-transform: uppercase;
}

.socure-c-phInput__selected-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  height: 100%;
}

.socure-c-country-code-container {
  height: 42px;
}

.socure-c-flabel__fields_dropdown-group {
  padding-left: 0 !important;
}

