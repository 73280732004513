.socure-c-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: var(--socure-global--LineHeight-24);
  color: var(--socure-global--color-white);
  border: none;
  border-radius: var(--socure-global--BorderRadius--m);
  transition-duration: .2s;
}

.socure-c-button .socure-c-button__icon--animate:hover {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}

.socure-c-button:disabled {
  cursor: not-allowed;
}

.socure-c-button:focus:not(.socure-c-button--icon-only),
.socure-c-button:focus-visible:not(.socure-c-button--icon-only) {
  outline: 2px solid var(--socure-global--color-teal-outline-input);
}

.socure-c-button:focus.socure-c-button--icon-only .socure-c-button__icon,
.socure-c-button:focus.socure-c-button--icon-only .socure-c-button__icon--end,
.socure-c-button:focus-visible.socure-c-button--icon-only .socure-c-button__icon,
.socure-c-button:focus-visible.socure-c-button--icon-only .socure-c-button__icon--end {
  outline: 2px solid var(--socure-global--color-teal-outline-input);
  border: 1px solid var(--socure-global--color-text-input-border-2);
  border-radius: 4px;
}

.socure-c-button__container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.socure-c-button-loader {
  position: relative;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.socure-c-button-loader+.socure-c-button__icon {
  margin-left: 8px;
}

.socure-c-button__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: .5em;
}

.socure-c-button__icon--end {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: .5em;
}

.socure-c-button--m {
  height: 32px;
  padding: 4px 12px;
  font-size: var(--socure-global--FontSize--xs);
}

.socure-c-button--m-icon {
  min-width: 71px;
  height: 32px;
  padding: 4px 16px 4px 12px;
  font-size: var(--socure-global--FontSize--xs);
}

.socure-c-button--l {
  min-width: 79px;
  height: 36px;
  padding: 6px 16px;
  font-size: var(--socure-global--FontSize--xs);
}

.socure-c-button--l-icon {
  min-width: 79px;
  height: 36px;
  padding: 6px 16px;
  font-size: var(--socure-global--FontSize--xs);
}

.socure-c-button--xl {
  min-width: 79px;
  height: 48px;
  font-size: var(--socure-global--FontSize--xs);
  font-weight: var(--socure-global--FontWeight--semi-bold);
  line-height: var(--socure-global--LineHeight-24);
}

.socure-c-button--border-radius-m {
  border-radius: 4px;
}

.socure-c-button--border-radius-l {
  border-radius: 8px;
}

.socure-c-button--expanded {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.socure-c-button--primary {
  background-color: var(--socure-global--color-teal-button);
}

.socure-c-button--primary:hover {
  background-color: var(--socure-global--color-teal-button-hover);
}

.socure-c-button--primary:active {
  background-color: var(--socure-global--color-teal-button);
}

.socure-c-button--primary:disabled {
  background-color: var(--socure-global--color-teal-button);
}

.socure-c-button--primary:focus,
.socure-c-button--primary:focus-visible {
  border: 1px solid var(--socure-global--color-content-background);
}

.socure-c-button--secondary {
  box-sizing: border-box;
  color: var(--socure-global--color-text-input-border-2);
  background-color: var(--socure-global--color-white);
  border: 1px solid var(--socure-global--color-text-input-border-2);
}

.socure-c-button--secondary:hover {
  background-color: var(--socure-secondary-button-hover);
}

.socure-c-button--secondary:active {
  background-color: var(--socure-global--color-secondary-button-active);
}

.socure-c-button--secondary:disabled {
  color: var(--socure-global--color-black);
  background-color: var(--socure-global--color-gray-96);
}

.socure-c-button--ghost {
  color: var(--socure-global--color-gray-20);
  background-color: var(--socure-global--color-white);
  border: solid 2px var(--socure-global--color-gray-80);
}

.socure-c-button--ghost:hover {
  background-color: var(--socure-global--color-gray-80);
}

.socure-c-button--ghost:active {
  background-color: var(--socure-global--color-gray-40);
  border: solid 2px var(--socure-global--color-gray-40);
}

.socure-c-button--ghost:disabled {
  color: var(--socure-global--color-gray-80);
  background-color: var(--socure-global--color-white);
}

.socure-c-button--dark {
  color: var(--socure-global--color-white);
  background: var(--socure-global--color-text-input-border-2);
  border: 1px solid var(--socure-global--color-text-input-border-2);
}

.socure-c-button--dark:hover {
  background-color: var(--socure-global--color-dark-button-hover-color);
  border: 1px solid var(--socure-global--color-dark-button-hover-color);
}

.socure-c-button--dark:active {
  background-color: var(--socure-global--color-dark-button-active-color);
}

.socure-c-button--dark:focus,
.socure-c-button--dark:focus-visible {
  border: 1px solid var(--socure-global--color-white);
}

.socure-c-button--danger {
  background-color: var(--socure-global--alert-error-color);
}

.socure-c-button--danger:hover {
  background-color: var(--socure-global--color-red-40);
}

.socure-c-button--danger:active {
  background-color: var(--socure-global--color-red-40);
}

.socure-c-button--danger:disabled {
  background-color: var(--socure-global--color-red-100);
}

.socure-c-button--danger:focus,
.socure-c-button--danger:focus-visible {
  border: 1px solid var(--socure-global--color-white);
}

.socure-c-button--transparent {
  background-color: transparent;
  border: solid 2px var(--socure-global--color-white);
}

.socure-c-button--transparent:hover {
  border: solid 2px var(--socure-global--color-gray-40);
}

.socure-c-button--transparent:active {
  background-color: var(--socure-global--color-gray-40);
  border: solid 2px var(--socure-global--color-gray-40);
}

.socure-c-button--transparent:disabled {
  color: var(--socure-global--color-gray-80);
  background-color: var(--socure-global--color-white);
  border: solid 2px var(--socure-global--color-white);
}

.socure-c-button--icon-only {
  min-width: 0 !important;
  padding: 0;
  background-color: transparent;
  border: none;
}

.socure-c-button--icon-only .socure-c-button__icon {
  margin-right: 0 !important;
}

