.socure-c-datagrid-row .socure-c-datagrid-hover-content,
#table-results .socure-c-datagrid-hover-content {
  position: absolute;
  bottom: 100%;
  z-index: -1;
  width: 100%;
  max-width: 400px;
  height: 100%;
  max-height: 200px;
  overflow: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 6px rgba(0,0,0,0.25);
  opacity: 0;
  transition: .2s linear;
  transition-delay: 0s;
}

.socure-c-datagrid-row:hover .socure-c-datagrid-hover-content,
#table-results:hover .socure-c-datagrid-hover-content {
  z-index: 1;
  opacity: 1;
  transition-delay: 1s;
}

.socure-c-datagrid-row.socure-c-datagrid-row-hover div.row-entry,
#table-results.socure-c-datagrid-row-hover div.row-entry {
  cursor: pointer;
}

div#table-results {
  overflow: visible;
}

#story--datagrid--row-content-enabled,
.socure-c-datagrid-container {
  position: relative;
}

.socure-c-datagrid-container.socure-c-datagrid-tooltip .header-cell {
  font-weight: 700;
  font-size: 10px;
}

.socure-c-datagrid-container.socure-c-datagrid-tooltip #table-results .row-entry {
  height: 22px;
}

.socure-c-datagrid-container.socure-c-datagrid-tooltip #table-results .row-entry .data-cell {
  font-weight: 400;
  font-size: 11px;
}

#story--datagrid--row-content-enabled {
  position: relative;
}

