.socure-c-switch {
  --socure-c-switch--FontSize: var(--socure-global--FontSize--xs);
  --socure-c-switch--LineHeight: var(--socure-global--LineHeight-md);
  --socure-c-switch--Height: calc(var(--socure-c-switch--FontSize) * var(--socure-c-switch--LineHeight));
  --socure-c-switch--Offset: .125rem;
  --socure-c-switch__input--checked__toggle--BackgroundColor: var(--socure-global--color-orange-60);
  --socure-c-switch__input--checked__toggle--before--TranslateX: calc(100% + var(--socure-c-switch--Offset));
  --socure-c-switch__input--disabled__label--Color: var(--socure-global--color-gray-60);
  --socure-c-switch__input--disabled__toggle--BackgroundColor: var(--socure-global--color-gray-90);
  --socure-c-switch__toggle--Height: calc(var(--socure-c-switch--FontSize) * var(--socure-c-switch--LineHeight));
  --socure-c-switch__toggle--BackgroundColor: var(--socure-global--color-gray-80);
  --socure-c-switch__toggle--BorderRadius: var(--socure-c-switch--Height);
  --socure-c-switch__toggle--before--Width: calc(1rem);
  --socure-c-switch__toggle--before--Height: var(--socure-c-switch__toggle--before--Width);
  --socure-c-switch__toggle--before--Top: calc((var(--socure-c-switch--Height) - var(--socure-c-switch__toggle--before--Height)) / 2);
  --socure-c-switch__toggle--before--Left: var(--socure-c-switch__toggle--before--Top);
  --socure-c-switch__toggle--before--BackgroundColor: var(--socure-global--color-white);
  --socure-c-switch__toggle--before--BorderRadius: var(--socure-global--BorderRadius--l);
  --socure-c-switch__toggle--before--BoxShadow: var(--socure-global--BoxShadow--m);
  --socure-c-switch__toggle--before--Transition: transform .25s ease 0s;
  --socure-c-switch__toggle--Width: calc(var(--socure-c-switch--Height) + var(--socure-c-switch--Offset) + var(--socure-c-switch__toggle--before--Width));
  --socure-c-switch__label--Padding: var(--socure-global--spacer--s);
  --socure-c-switch__label--Color: var(--socure-global--color-black-01);
  position: relative;
  display: inline-block;
  height: var(--socure-c-switch--Height);
  font-size: var(--socure-c-switch--FontSize);
  line-height: var(--socure-c-switch--LineHeight);
  vertical-align: middle;
  cursor: pointer;
}

.socure-c-switch__input {
  position: absolute;
  cursor: pointer;
  opacity: 0;
}

.socure-c-switch__input:checked ~ .socure-c-switch__label {
  font-weight: var(--socure-global--FontWeight--bold);
  color: var(--socure-global--color-black-01);
}

.socure-c-switch__input:checked ~ .socure-c-switch__toggle {
  background-color: var(--socure-global--color-teal-border);
}

.socure-c-switch__input:checked ~ .socure-c-switch__toggle::before {
  transform: translateX(var(--socure-c-switch__input--checked__toggle--before--TranslateX));
}

.socure-c-switch__input:checked ~ .socure-m-off {
  display: none;
}

.socure-c-switch__input:not(:checked) ~ .socure-c-switch__label {
  color: var(--socure-global--color-black-01);
}

.socure-c-switch__input:not(:checked) ~ .socure-m-on {
  display: none;
}

.socure-c-switch__input:disabled ~ .socure-c-switch__toggle {
  width: 14px;
  height: 14px;
  margin-top: var(--socure-global--spacer--xs);
}

.socure-c-switch__input:disabled ~ .socure-c-switch__toggle::before {
  width: 0;
  height: 0;
}

.socure-c-switch__toggle {
  position: relative;
  display: inline-block;
  width: var(--socure-c-switch__toggle--Width);
  height: var(--socure-c-switch__toggle--Height);
  background-color: var(--socure-global--color-switch-background);
  border-radius: var(--socure-c-switch__toggle--BorderRadius);
}

.socure-c-switch__toggle::before {
  position: absolute;
  top: var(--socure-c-switch__toggle--before--Top);
  left: var(--socure-c-switch__toggle--before--Left);
  display: block;
  width: var(--socure-c-switch__toggle--before--Width);
  height: var(--socure-c-switch__toggle--before--Height);
  content: "";
  background-color: var(--socure-c-switch__toggle--before--BackgroundColor);
  border-radius: var(--socure-c-switch__toggle--before--BorderRadius);
  box-shadow: var(--socure-c-switch__toggle--before--BoxShadow);
  transition: var(--socure-c-switch__toggle--before--Transition);
}

.socure-c-switch__label {
  display: inline-block;
  color: var(--socure-c-switch__label--Color);
  vertical-align: top;
}

.socure-c-switch__label--padLeft {
  padding-left: var(--socure-c-switch__label--Padding);
}

.socure-c-switch__label--padRight {
  padding-right: var(--socure-c-switch__label--Padding);
}

