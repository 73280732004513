.socure-c-loader--container {
  background-color: #454d5f;
  background-color: rgba(69,77,95,0.1);
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.socure-c-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 100px;
  height: 100px;
  border: 5px solid transparent;
  border-top-color: var(--socure-global--color-orange-20);
  border-radius: 100%;
  transform: translate(-50%, -50%);
  animation: rotate 1.2s linear infinite;
}

.socure-c-loader::before,
.socure-c-loader::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  content: "";
  border: 4px solid transparent;
  border-radius: 100%;
}

.socure-c-loader::before {
  width: 80px;
  height: 80px;
  border-top-color: var(--socure-global--color-orange-60);
  transform: translate(-50%, -50%);
  animation: rotate 1.1s linear infinite;
}

.socure-c-loader::after {
  width: 60px;
  height: 60px;
  border-top-color: var(--socure-global--color-orange-100);
  transform: translate(-50%, -50%);
  animation: rotate 1s linear infinite;
}

.socure-c-loader-icon--container {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

