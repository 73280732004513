.socure-c-bc-container {
  display: flex;
  align-items: center;
}

.socure-c-bc-each-group {
  display: inline-flex;
}

.socure-c-bc-each-crumb {
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
  font-size: 12px;
  line-height: 18px;
  color: var(--socure-global--color-gray-20);
  cursor: pointer;
}

.socure-c-bc-each-crumb.socure-c-bc-selected-crumb {
  color: var(--socure-global--color-blue-60);
}

