.socure-c-radio-button-outer-container>.socure-c-radio-button-container:first-child {
  border-radius: 4px 0 0 4px;
}

.socure-c-radio-button-outer-container>.socure-c-radio-button-container:last-child {
  border-right: 1px solid var(--socure-global--color-gray-80);
  border-radius: 0 4px 4px 0;
}

.socure-c-radio-button-outer-container-variant-2 {
  width: fit-content;
  height: 44px;
  background: var(--socure-global--color-white);
  border: 1px solid var(--socure-global--color-gray-20);
  border-radius: 8px;
}

.socure-c-radio-button-outer-container-variant-2>.socure-c-radio-button-container:first-child {
  margin-left: 3px;
  border-radius: 6px;
}

.socure-c-radio-button-outer-container-variant-2>.socure-c-radio-button-container:last-child {
  margin-right: 3px;
  border: none;
  border-radius: 6px;
}

.socure-c-radio-button-outer-container-variant-2>.socure-c-radio-button-container {
  margin-top: 3px;
  border: none;
  border-radius: 6px;
}

.socure-c-radio-button-outer-container-variant-2>.socure-c-radio-button-container-selected {
  box-shadow: none;
}

.socure-c-radio-button-outer-container-variant-2.socure-c-radio-button-outer-container-dark {
  background: var(--socure-global--color-radio-background);
}

.socure-c-radio-button-outer-container-variant-2.socure-c-radio-button-outer-container-dark>.socure-c-radio-button-container {
  border: none;
  border-radius: 6px;
}

.socure-c-radio-button-outer-container-expand {
  display: flex;
  width: 100%;
  overflow-x: hidden;
}

.socure-c-radio-button-outer-container-expand>.socure-c-radio-button-container {
  padding: 6px;
}

.socure-c-radio-button-outer-container-dark>.socure-c-radio-button-container:last-child {
  border-right: 1px solid rgba(227,228,230,0.1);
}

.socure-c-radio-button-outer-container-dark .socure-c-radio-button-container {
  border: 1px solid rgba(227,228,230,0.1);
}

.socure-c-radio-button-outer-container-dark .socure-c-radio-button-container-normal {
  color: var(--socure-global--color-white);
  background: var(--socure-global--color-radio-background);
}

.socure-c-radio-button-outer-container-dark .socure-c-radio-button-container-disabled {
  color: var(--socure-global--color-white);
  cursor: not-allowed;
  background: var(--socure-global--color-radio-background);
}

.socure-c-radio-circle-container {
  position: relative;
  width: fit-content;
  height: 16px;
  margin-bottom: 16px;
  white-space: nowrap;
  cursor: pointer;
}

.socure-c-radio-circle-container .socure-c-radio-circle-inner-container {
  color: var(--socure-global--color-text-input-border-2);
}

.socure-c-radio-circle-container:not(.socure-c-radio-circle-container-disabled):hover .socure-c-radio-circle-inner-circle-selected {
  color: var(--socure-global--color-teal-border);
}

.socure-c-radio-circle-container:not(.socure-c-radio-circle-container-disabled):hover .socure-c-radio-circle-inner-container {
  color: var(--socure-global--color-teal-border);
}

.socure-c-radio-circle-container:not(.socure-c-radio-circle-container-disabled):focus-visible .socure-c-radio-circle-inner-circle-selected {
  color: var(--socure-global--color-teal-border);
}

.socure-c-radio-circle-container:not(.socure-c-radio-circle-container-disabled):focus-visible .socure-c-radio-circle-inner-container {
  color: var(--socure-global--color-teal-border);
}

.socure-c-radio-circle-container:not(.socure-c-radio-circle-container-disabled):focus-visible svg {
  outline: 2px solid var(--socure-global--color-teal-outline-input);
  border-radius: 16px;
}

.socure-c-radio-circle-container-disabled {
  cursor: not-allowed;
}

.socure-c-radio-circle-container-disabled .socure-c-radio-circle-inner-circle-selected,
.socure-c-radio-circle-container-disabled .socure-c-radio-circle-inner-circle {
  color: var(--socure-global--cb-disabled-border);
}

.socure-c-radio-circle-container-disabled .socure-c-radio-circle-inner-container {
  color: var(--socure-global--cb-disabled-border);
}

.socure-c-radio-circle-inner-circle-selected,
.socure-c-radio-circle-inner-circle {
  color: var(--socure-global--color-teal-border);
}

.socure-c-radio-circle-text-container {
  position: absolute;
  left: 24px;
  display: inline-block;
  font-size: var(--socure-global--FontSize--xs);
  line-height: 20px;
  color: var(--socure-global--color-text-input-border-2);
}

.socure-c-radio-circle-text-container.socure-c-radio-circle-text-container-selected {
  font-weight: 700;
}

.socure-c-radio-button-container {
  box-sizing: border-box;
  display: inline-block;
  flex: 1 1 0;
  flex-direction: row;
  align-items: center;
  height: 36px;
  padding: 6px 16px;
  overflow: hidden;
  font-size: var(--socure-global--FontSize--xs);
  text-align: center;
  cursor: pointer;
  border: 1px solid var(--socure-global--color-gray-80);
  border-right: 0;
}

.socure-c-radio-button-container.socure-c-radio-button--primary {
  background-color: var(--socure-global--color-teal-button);
}

.socure-c-radio-button-container.socure-c-radio-button--dark {
  background-color: var(--socure-global--color-dark-radio-button-color);
}

.socure-c-radio-button-container.socure-c-radio-button--warning {
  background-color: var(--socure-global--color-menu-indicator);
}

.socure-c-radio-button-container-normal {
  color: var(--socure-global--color-gray-20);
  background: var(--socure-global--color-white);
}

.socure-c-radio-button-container-selected {
  font-weight: var(--socure-global--FontWeight--bold);
  color: var(--socure-global--color-white);
  background: var(--socure-global--color-teal-border);
  border-radius: 0;
  box-shadow: 0 4px 8px rgba(255,105,0,0.2);
}

.socure-c-radio-button-container-disabled {
  color: var(--socure-global--color-gray-20);
  cursor: not-allowed;
  background: var(--socure-global--color-white);
}

.socure-c-radio-button-outer-container-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.socure-c-radio-button-outer-container-row .socure-c-radio-circle-container {
  display: flex;
  align-items: center;
  margin: 0 20px 0 0;
}

.socure-c-radio-button-outer-container-row .socure-c-radio-circle-text-container {
  position: relative;
  left: 0;
  padding: 0 0 0 8px;
}

.socure-c-radio-button-outer-container-tristate {
  width: 68px;
  height: 16px;
  border-radius: 16px;
  opacity: 1;
}

.socure-c-radio-button-outer-container-tristate>.socure-c-radio-button-container-tristate:first-child {
  top: 4px;
  left: 8px;
}

.socure-c-radio-button-outer-container-tristate>.socure-c-radio-button-container-tristate:last-child {
  top: 4px;
  left: 52px;
}

.socure-c-radio-button-outer-container-tristate>.socure-c-radio-button-container-tristate-selected:first-child {
  top: 2px;
  left: 2px;
}

.socure-c-radio-button-outer-container-tristate>.socure-c-radio-button-container-tristate-selected:last-child {
  top: 2px;
  left: 46px;
}

.socure-c-radio-button-outer-container-disabled {
  position: static;
  width: 16px;
  height: 16px;
  background: #ea2424 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
}

.socure-c-radio-button-container-tristate {
  position: absolute;
  top: 4px;
  left: 30px;
  display: inline-block;
  width: 8px;
  height: 8px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: .26;
}

.socure-c-radio-button-container-tristate-selected {
  position: absolute;
  top: 2px;
  left: 24px;
  width: 20px;
  height: 12px;
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
}

.socure-c-radio-button-container-tristate-selected-lable {
  position: absolute;
  left: 105px;
  display: inline-flex;
  font-size: var(--socure-global--FontSize--2xs);
  font-weight: var(--socure-global--FontWeight--bold);
  color: #222;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: .06px;
  opacity: 1;
}

.socure-c-radio-button-container-tristate-selected-lable-disabled {
  position: static;
  padding-left: 8px;
}

.socure-c-outer-container-tristate {
  display: flex;
}

