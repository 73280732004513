.socure-c-carousel-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.socure-c-carousel-wrapper {
  position: relative;
  display: flex;
  width: 100%;
}

.socure-c-carousel-content-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.socure-c-carousel-content {
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.socure-c-carousel-content::-webkit-scrollbar {
  display: none;
}

.socure-c-carousel-content>* {
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
}

.socure-c-carousel-left-arrow,
.socure-c-carousel-right-arrow {
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 48px;
  height: 48px;
  background-color: var(--socure-global--color-white);
  border: 1px solid var(--socure-global--color-gray-80);
  border-radius: 24px;
  transform: translateY(-50%);
}

.socure-c-carousel-left-arrow {
  left: 24px;
}

.socure-c-carousel-right-arrow {
  right: 24px;
}

.socure-c-carousel-content.socure-c-carousel-show-2>* {
  width: 50%;
}

.socure-c-carousel-content.socure-c-carousel-show-3>* {
  width: calc(100% / 3);
}

.socure-c-carousel-content.socure-c-carousel-show-4>* {
  width: calc(100% / 4);
}

