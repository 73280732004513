.socure-c-dropdownitem {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  --socure-c-dropdownitem-MinHeight: 48px;
  --socure-c-dropdownitem--Padding: 16px;
  min-height: var(--socure-c-dropdownitem-MinHeight);
  padding: var(--socure-c-dropdownitem--Padding);
  font-size: var(--socure-global--FontSize--2xs);
  line-height: var(--socure-global--LineHeight-18);
}

.socure-c-dropdownitem:first-child {
  border-radius: 4px 4px 0 0;
}

.socure-c-dropdownitem:last-child {
  border-radius: 0 0 4px 4px;
}

.socure-c-dropdownitem.socure-c-dropdownItem__focussed {
  font-weight: var(--socure-global--FontWeight--bold);
  background-color: var(--socure-global--color-gray-90);
}

.socure-c-dropdownitem.socure-c-dropdownItem__dark:hover,
.socure-c-dropdownitem.socure-c-dropdownItem__dark:focus {
  background-color: rgba(255,255,255,0.1);
}

.socure-c-dropdownitem-menu {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  --socure-c-dropdownitem-MinHeight: 24px;
  --socure-c-dropdownitem--Padding: 4px;
  min-height: var(--socure-c-dropdownitem-MinHeight);
  padding: 0 var(--socure-c-dropdownitem--Padding);
  margin: 0 var(--socure-c-dropdownitem--Padding);
  font-size: var(--socure-global--FontSize--xs);
  line-height: var(--socure-global--LineHeight-28);
}

.socure-c-dropdownitem-menu.socure-c-dropdownItem__focussed {
  background-color: var(--socure-global--color-gray-90);
}

.socure-c-dropdownitem__disabled {
  color: var(--socure-global--color-gray-60);
  pointer-events: none;
  opacity: .5;
}

.socure-c-dropdownitem-combo-search {
  border-radius: 4px;
}

.socure-c-dropdownitem-combo-search:hover,
.socure-c-dropdownitem-combo-search.socure-c-dropdownItem__focussed {
  background-color: #F5F5F5;
}

.socure-c-combo-search-active--dropdown-item-indicator {
  width: 80%;
  border-top: 1px solid #DDDDDD;
  margin: 0.75rem 0 0.35rem 0.75rem;
}

