.socure-c-nc-nav-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 12px;
  padding-bottom: 6px;
  border-top: 1px solid var(--socure-global--color-data-table-header-border);
}

.socure-c-nc-page-dropdown-controls {
  height: 32px;
}

.socure-c-nc-page-dropdown-controls>div:first-child {
  margin-top: 0px;
  height: 32px;
  width: 60px;
}

.socure-c-nc-page-dropdown-controls button {
  height: 32px;
}

.socure-c-nc-page-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.socure-c-nc-page-details {
  margin-left: 8px;
  font-family: var(--socure-global-font-family);
  font-size: var(--socure-global--FontSize--13);
  font-weight: var(--socure-global--FontWeight--bold);
  line-height: var(--socure-global--LineHeight-18);
  color: var(--socure-global--color-data-table-pagination);
}

.socure-c-nc-page-details-text {
  margin-left: 8px;
  font-family: var(--socure-global-font-family);
  font-size: var(--socure-global--FontSize--13);
  font-weight: var(--socure-global--FontWeight--normal);
  line-height: var(--socure-global--LineHeight-18);
  color: var(--socure-global--color-data-table-pagination);
}

