.socure-c-file-upload-outer-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 312px;
}

.socure-c-file-upload-outer-container.socure-c-file-upload-expand {
  width: 100%;
}

.socure-c-file-upload-label-text {
  font-size: var(--socure-global--FontSize--xs);
  line-height: var(--socure-global--LineHeight-19);
  color: var(--socure-global--color-popver-heading);
}

.socure-c-file-upload-label-text-error {
  color: var(--socure-global--color-red-60);
}

.socure-c-file-upload-inner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 56px;
  margin-top: 8px;
  border-radius: 4px;
}

.socure-c-file-upload-icon {
  display: flex;
  margin-left: 8px;
}

.socure-c-fileupload-text {
  margin-left: 8px;
}

.socure-c-fileupload-text-normal {
  color: var(--socure-global--color-file-upload-text-default);
}

.socure-c-fileupload-text-disabled {
  color: var(--socure-global--color-gray-40);
}

.socure-c-file-upload-label-text-below {
  position: absolute;
  top: 88px;
  width: 312px;
  height: 20px;
  font-size: var(--socure-global--FontSize--2xs);
  line-height: 20px;
}

.socure-c-file-upload-label-text-below-normal {
  color: var(--socure-global--color-gray-30);
}

.socure-c-file-upload-label-text-below-error {
  color: var(--socure-global--color-red-60);
}

.socure-c-file-upload-disabled-background {
  pointer-events: none;
  background: var(--socure-global--color-gray-90);
}

.socure-c-file-upload-error-background {
  background: var(--socure-global--color-red-100);
  border: 1px dashed var(--socure-global--color-red-60);
}

.socure-c-file-upload-normal-background {
  box-sizing: border-box;
  background: var(--socure-global--color-file-upload-background);
  border: 1px dashed var(--socure-global--color-file-upload-border);
  border-radius: 4px;
}

.socure-c-file-upload-cursor {
  cursor: pointer;
}

