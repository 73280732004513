.socure-c-textInput-OutterContainer {
  --socure-c-textInput--LineHeight: var(--socure-global--LineHeight-24);
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.socure-c-textInput-OutterContainer.socure-c-textInput-expanded {
  display: flex;
  flex: 1;
}

.socure-c-textInput-OutterContainer.socure-c-textInput-disabled {
  opacity: .4;
}

.socure-c-textInput-InnerContainer {
  position: relative;
  align-items: flex-end;
  width: 100%;
}

.socure-c-textInput-InnerContainer>input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 44px;
  padding: 12px;
  margin-top: 12px;
  font-size: var(--socure-global--FontSize--xs);
  line-height: var(--socure-global--LineHeight-24);
  background-color: transparent !important;
  border: 1px solid var(--socure-global--color-text-input-border-2);
  color: var(--socure-global--color-text-input-border-2);
  border-radius: 4px;
}

.socure-c-textInput-InnerContainer>input.socure-c-textInput-variant-2 {
  border-radius: 8px;
}

.socure-c-textInput-InnerContainer>input:-webkit-autofill,
.socure-c-textInput-InnerContainer>input:-webkit-autofill:hover,
.socure-c-textInput-InnerContainer>input:-webkit-autofill:focus,
.socure-c-textInput-InnerContainer>input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--socure-global--color-white) inset !important;
}

.socure-c-textInput-InnerContainer>input:focus:not(.socure-c-textInput-input-validation-error) {
  color: var(--socure-global--colo\r-black);
  border: 1px solid var(--socure-global--color-teal-border) !important;
  outline: 2px solid var(--socure-global--color-teal-outline-input) !important;
}

.socure-c-textInput-InnerContainer>input:focus.socure-c-textInput-input-validation-error {
  outline: 2px solid var(--socure-global--color-text-input-outline-error) !important;
}

.socure-c-textInput-InnerContainer>input ~ .socure-c-textInput-placeholder-label {
  font-size: var(--socure-global--FontSize--2xs);
  font-weight: var(--socure-global--FontWeight--bold);
  line-height: var(--socure-global--LineHeight-24);
  color: var(--socure-global--color-switch-label-disabled);
  text-transform: uppercase;
  background-color: var(--socure-global--color-white);
}

.socure-c-textInput-InnerContainer>input:focus ~ .socure-c-textInput-placeholder-label {
  top: 0;
  left: 8px;
  padding: 2px 4px;
  color: var(--socure-global--color-switch-label-disabled) !important;
}

.socure-c-textInput-InnerContainer>input[type="number"] {
  appearance: textfield;
}

.socure-c-textInput-InnerContainer>input::-webkit-outer-spin-button,
.socure-c-textInput-InnerContainer>input::-webkit-inner-spin-button {
  appearance: none;
}

.socure-c-textInput-placeholder-label {
  position: absolute;
  top: 0;
  left: 8px;
  padding: 2px 4px;
  font-size: var(--socure-global--FontSize--xs);
  line-height: var(--socure-global--LineHeight-24);
  color: var(--socure-global--color-black);
  background-color: var(--socure-global--color-white);
  transition: 300ms;
  transition-property: all;
}

.socure-c-textInput-InnerContainer>input.socure-c-textInput-disabled-input {
  pointer-events: none;
  cursor: not-allowed;
  background-color: var(--socure-secondary-button-hover) !important;
  border: 1px solid var(--socure-secondary-button-hover);
}

.socure-c-textInput-validation-error-label {
  position: absolute;
  top: 58px;
  font-size: 14px;
  line-height: var(--socure-global--LineHeight-18);
  color: var(--socure-global--color-text-input-error) !important;
}

.socure-c-textInput-validation-error-label .error-feather-icon {
  display: inline-block;
  height: 16px;
  line-height: 14px;
  margin-right: 5px;
  vertical-align: middle;
}

.socure-c-textInput-validation-error-label>.socure-c-tooltip-icon {
  margin-left: 8px;
}

.socure-c-textInput-input-validation-error {
  border: 1px solid var(--socure-global--color-text-input-error) !important;
  outline: none !important;
}

.socure-c-textInput-validation-error-label-visible {
  display: inline-block;
}

.socure-c-textInput-validation-error-label-hidden {
  display: none;
}

