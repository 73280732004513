.socure-c-dropdown {
  display: flex;
  flex-flow: column wrap;
  align-content: normal;
  align-items: normal;
  justify-content: normal;
  font-size: 16px;
  line-height: var(--socure-global--LineHeight-24);
}

.socure-c-dropdown__placeholder-label {
  position: absolute;
  top: -10px;
  left: 8px;
  padding: 2px 4px;
  font-size: var(--socure-global--FontSize--2xs);
  font-weight: var(--socure-global--FontWeight--bold);
  line-height: var(--socure-global--LineHeight-24);
  color: var(--socure-global--color-switch-label-disabled);
  text-transform: uppercase;
  background-color: var(--socure-global--color-white);
}

.socure-c-dropdown__placeholder-label.socure-c-dropdown__placeholder-label--dark {
  color: var(--socure-global--color-white);
  background-color: transparent;
}

.socure-c-dropdown__placeholder-label--empty {
  top: 10px;
  left: 12px;
  font-size: var(--socure-global--FontSize--2xs);
  font-weight: var(--socure-global--FontWeight--normal);
  text-transform: none;
  transition: 300ms;
  transition-property: top, font-size;
}

.socure-c-dropdown__placeholder-label--disabled {
  color: var(--socure-global--color-gray-60);
  background-color: var(--socure-global--color-gray-90);
}

.socure-c-dropdown-error {
  border: 1px solid var(--socure-global--color-red-60) !important;
}

.socure-c-dropdown-validation-error-label {
  position: absolute;
  top: 48px;
  left: 0;
  font-size: 14px;
  line-height: var(--socure-global--LineHeight-18);
  color: var(--socure-global--color-text-input-error);
}

.socure-c-dropdown-validation-error-label .error-feather-icon {
  display: inline-block;
  height: 16px;
  line-height: 14px;
  margin-right: 5px;
  vertical-align: middle;
}

.socure-c-dropdown-validation-error-label-visible {
  display: inline-block;
}

.socure-c-dropdown-validation-error-label-hidden {
  display: none;
}

.socure-popper-container-override {
  border-radius: 4px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  box-shadow: rgba(0,0,0,0.07) 0px 1px 1px,rgba(0,0,0,0.07) 0px 2px 2px,rgba(0,0,0,0.07) 0px 4px 4px,rgba(0,0,0,0.07) 0px 8px 8px,rgba(0,0,0,0.07) 0px 16px 16px !important;
}

.socure-c-dropdown-search {
  padding: 20px 20px 0 20px;
}

.socure-c-dropdown-popper>div {
  overflow: unset;
}

.socure-c-dropdown-popper .socure-c-dropdown-container {
  display: flex;
  flex-direction: column;
}

.socure-c-dropdown-popper .socure-c-dropdown-container .socure-c-dropdownlist {
  overflow: auto;
  overflow-x: hidden;
  max-height: 200px;
}

.socure-c-dropdown-popper .socure-c-dropdown-container .socure-c-dropdown-action-container {
  padding: 12px 23px;
  border-top: 1px solid #eee;
  box-shadow: rgba(0,0,0,0.07) 0px 1px 1px;
}

.socure-c-dropdown-popper .socure-c-dropdown-container .socure-c-dropdown-action-container button {
  margin-right: 20px;
}

.socure-combo-search-popper-container-override {
  margin-top: 0.8rem !important;
  box-shadow: 0px 2px 8px #00000033 !important;
  border-radius: 4px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

